import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import DynamicIcon from '../common/DynamicIcon'
import Text from '../common/Text'
import { LangKeys } from '../../i18n'
import MobileSlider from '../common/MobileSlider'
import mediaQuery from '../../styles/media'

interface BenefitsProps {
    format: string
    className?: string
}

const BenefitsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 calc((100% - 1128px) / 2);

    h2 {
        color: ${props => props.theme.colors.main};
        margin-bottom: 2rem;
    }
`

const BenefitsContainer = styled.div`
    position: relative;
    display: flex;
    padding: 24px 72px;
    gap: 48px;
    width: 1128px;
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadow.classic};
    border-radius: 8px;

    :after,
    :before {
        content: '';
        position: absolute;
        display: block;
    }

    :before {
        background-color: ${props => props.theme.colors.main};
        width: 150px;
        height: 150px;
        z-index: -1;
        opacity: 0.25;
        transform: rotate(75deg);
        left: -80px;
        bottom: -64px;
    }

    :after {
        border: solid 15px ${props => props.theme.colors.accent};
        width: 120px;
        height: 120px;
        z-index: -1;
        opacity: 0.25;
        transform: rotate(-60deg);
        transform-origin: center;
        right: -40px;
        top: -56px;
    }

    ${mediaQuery('md')} {
        display: flex;
        width: 100%;
        padding: 80px 0;
        margin: 0;
        height: fit-content;
        gap: 0;
        background: none;
        box-shadow: none;
        flex-direction: column;
        overflow: hidden;

        :after {
            top: 120px;
        }
        :before {
            bottom: 56px;
        }
        h2 {
            padding: 0 16px;
            color: ${props => props.theme.colors.main};
            margin-bottom: 2rem;
            text-align: center;
        }
    }
`

const BenefitsControls = styled.div`
    display: flex;
    position: relative;
    gap: 24px;
    width: 288px;
    flex-shrink: 0;
    align-items: center;

    ${mediaQuery('md')} {
        justify-content: center;
    }
`

const IconContainer = styled.div`
    display: flex;
    position: absolute;
    background-color: ${props => props.theme.colors.main};
    padding: 12px;
    transition: transform 0.5s ease-in-out, background-color 0.25s 0.25s ease-in-out, scale 0.25s ease-in-out;
    transform: translate3d(0, 0, 0);
    border-radius: 4px;

    &.active {
        background-color: ${props => props.theme.colors.accent};
        transform: translate3d(108px, 0px, 30px) scale(calc(4 / 3));
        transform-origin: center;
        z-index: 2;
    }

    &.left {
        transform: translate3d(0, 0px, -60px) scale(1);
        transform-origin: center;
        z-index: 1;
        transition: transform 0.5s ease-in-out, background-color 0.25s 0s ease-in-out, scale 0.25s ease-in-out;
    }

    &.right {
        transform: translate3d(216px, 0px, -30px) scale(1);
        transform-origin: center;
        z-index: 1;
        transition: transform 0.5s ease-in-out, background-color 0.25s 0s ease-in-out, scale 0.25s ease-in-out;
    }

    &.temp-index {
        z-index: 0;
        scale: 0.8;
    }

    :hover {
        :not(.active) {
            cursor: pointer;
        }
    }

    ${mediaQuery('md')} {
        position: absolute;
        background-color: ${props => props.theme.colors.accent};
        top: -44px;
        left: calc(50% - 44px);
        right: calc(50% - 44px);
    }
`

const BenefitsContent = styled.div`
    display: flex;
    flex-direction: column;

    h4 {
        transition: all 0.25s ease-in-out;
        font-weight: bold;
        color: ${props => props.theme.colors.accent};
    }

    p {
        transition: all 0.25s ease-in-out;
        height: 6em;

        ${mediaQuery('md')} {
            height: fit-content;
        }
    }

    .hidden {
        opacity: 0;
    }
`

const SlideWrapper = styled.div`
    position: relative;
    display: flex;
    width: calc(100vw - 32px);
    background-color: ${props => props.theme.colors.white};
    flex-shrink: 0;
    flex-direction: column;
    padding: 56px 16px 32px 16px;
    margin-top: 56px;
    border-radius: 8px;

    p {
        margin: 16px 0 0 0;
    }

    h4 {
        line-height: 2.5rem;
        text-align: center;
        color: ${props => props.theme.colors.main};
    }
`

const mobileSlides: ReactElement[] = [
    <SlideWrapper key={0}>
        <IconContainer>
            <DynamicIcon icon="performance" iconColor="white" size={64} />
        </IconContainer>
        <BenefitsContent>
            <h4>
                <Text value="benefitsNbr1Title" />
            </h4>
            <p>
                <Text value="benefitsNbr1Content" />
            </p>
        </BenefitsContent>
    </SlideWrapper>,
    <SlideWrapper key={1}>
        <IconContainer>
            <DynamicIcon icon="wellbeing" iconColor="white" size={64} />
        </IconContainer>
        <BenefitsContent>
            <h4>
                <Text value="benefitsNbr2Title" />
            </h4>
            <p>
                <Text value="benefitsNbr2Content" />
            </p>
        </BenefitsContent>
    </SlideWrapper>,
    <SlideWrapper key={2}>
        <IconContainer>
            <DynamicIcon icon="support" iconColor="white" size={64} />
        </IconContainer>
        <BenefitsContent>
            <h4>
                <Text value="benefitsNbr3Title" />
            </h4>
            <p>
                <Text value="benefitsNbr3Content" />
            </p>
        </BenefitsContent>
    </SlideWrapper>
]

const Benefits: React.FC<BenefitsProps> = ({ format, className }) => {
    const [currentBenefit, setCurrentBenefit] = useState(2)
    const [benefitCurrentTitle, setBenefitCurrentTitle] = useState<LangKeys>('benefitsNbr2Title')
    const [benefitCurrentContent, setBenefitCurrentContent] = useState<LangKeys>('benefitsNbr2Content')
    const PerfIcon = useRef(null)
    const WellbeingIcon = useRef(null)
    const SupportIcon = useRef(null)
    const [perfIconClass, setPerfIconClass] = useState('left')
    const [wellbeingIconClass, setWellbeingIconClass] = useState('active')
    const [supportIconClass, setSupportIconClass] = useState('right')
    const [contentFadeClass, setContentFadeClass] = useState('')

    useEffect(() => {
        if (PerfIcon.current !== null && WellbeingIcon.current !== null && SupportIcon.current !== null) {
            if (currentBenefit === 1) {
                setBenefitCurrentTitle('benefitsNbr1Title')
                setBenefitCurrentContent('benefitsNbr1Content')
                if (perfIconClass === 'left') {
                    setPerfIconClass('active')
                    setWellbeingIconClass('right')
                    setSupportIconClass('left temp-index')
                    setTimeout(() => {
                        setSupportIconClass('left')
                    }, 250)
                } else if (perfIconClass === 'right') {
                    setPerfIconClass('active')
                    setWellbeingIconClass('right temp-index')
                    setSupportIconClass('left')
                    setTimeout(() => {
                        setWellbeingIconClass('right')
                    }, 250)
                }
            } else if (currentBenefit === 2) {
                setBenefitCurrentTitle('benefitsNbr2Title')
                setBenefitCurrentContent('benefitsNbr2Content')
                if (wellbeingIconClass === 'left') {
                    setPerfIconClass('left temp-index')
                    setWellbeingIconClass('active')
                    setSupportIconClass('right')
                    setTimeout(() => {
                        setPerfIconClass('left')
                    }, 250)
                } else if (wellbeingIconClass === 'right') {
                    setPerfIconClass('left')
                    setWellbeingIconClass('active')
                    setSupportIconClass('right temp-index')
                    setTimeout(() => {
                        setSupportIconClass('right')
                    }, 250)
                }
            } else {
                setBenefitCurrentTitle('benefitsNbr3Title')
                setBenefitCurrentContent('benefitsNbr3Content')
                if (supportIconClass === 'left') {
                    setPerfIconClass('right')
                    setWellbeingIconClass('left temp-index')
                    setSupportIconClass('active')
                    setTimeout(() => {
                        setWellbeingIconClass('left')
                    }, 250)
                } else if (supportIconClass === 'right') {
                    setPerfIconClass('right temp-index')
                    setWellbeingIconClass('left')
                    setSupportIconClass('active')
                    setTimeout(() => {
                        setPerfIconClass('right')
                    }, 250)
                }
            }
        }

        setTimeout(() => {
            setContentFadeClass('')
        }, 250)
    }, [currentBenefit])

    return format === 'desktop' ? (
        <BenefitsWrapper className={className}>
            <h2>
                <Text value="benefits.title" />
            </h2>
            <BenefitsContainer>
                <BenefitsControls>
                    <IconContainer
                        ref={PerfIcon}
                        onClick={() => {
                            if (currentBenefit !== 1) {
                                setContentFadeClass('hidden')
                                setTimeout(() => {
                                    setCurrentBenefit(1)
                                }, 200)
                            }
                        }}
                        className={perfIconClass}
                    >
                        <DynamicIcon icon="performance" iconColor="white" size={48} />
                    </IconContainer>
                    <IconContainer
                        ref={WellbeingIcon}
                        onClick={() => {
                            if (currentBenefit !== 2) {
                                setContentFadeClass('hidden')
                                setTimeout(() => {
                                    setCurrentBenefit(2)
                                }, 200)
                            }
                        }}
                        className={wellbeingIconClass}
                    >
                        <DynamicIcon icon="wellbeing" iconColor="white" size={48} />
                    </IconContainer>
                    <IconContainer
                        ref={SupportIcon}
                        onClick={() => {
                            if (currentBenefit !== 3) {
                                setContentFadeClass('hidden')
                                setTimeout(() => {
                                    setCurrentBenefit(3)
                                }, 200)
                            }
                        }}
                        className={supportIconClass}
                    >
                        <DynamicIcon icon="support" iconColor="white" size={48} />
                    </IconContainer>
                </BenefitsControls>
                <BenefitsContent>
                    <h4 className={contentFadeClass}>
                        <Text value={benefitCurrentTitle} />
                    </h4>
                    <p className={contentFadeClass}>
                        <Text value={benefitCurrentContent} />
                    </p>
                </BenefitsContent>
            </BenefitsContainer>
        </BenefitsWrapper>
    ) : (
        <BenefitsContainer className={className}>
            <h2>
                <Text value="benefits.title" />
            </h2>
            <MobileSlider slidesList={mobileSlides} />
        </BenefitsContainer>
    )
}

export default Benefits
