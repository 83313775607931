import React from 'react'
import styled from 'styled-components'
import mediaQuery from '../../styles/media'
import Text from './Text'
import { LangKeys } from '../../i18n/types'

interface TitleProps {
    sectionTitle: LangKeys
    sectionSubTitle: LangKeys
    titleAlignement: string
    needsPadding?: boolean
    className?: string
    decorated?: boolean
    decorationStyle?: number
}

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;

    &.center {
        text-align: center;
    }

    &.left {
        text-align: left;

        h3 {
            margin-left: 0;
            margin-right: auto;
        }
    }

    &.right {
        text-align: right;

        h3 {
            margin-right: 0;
            margin-left: auto;
        }
    }

    &.mobile-padding {
        ${mediaQuery('md')} {
            padding: 0 16px;
        }
    }
`

const DecorationSquares = styled.div`
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;

    &.style-1 {
        top: -44px;
        left: -52px;

        span {
            display: block;
            position: absolute;

            &:nth-of-type(1) {
                width: 16px;
                height: 16px;
                background-color: ${props => props.theme.colors.accent};
                top: 8px;
                left: 26px;
                border-radius: 2px;
            }

            &:nth-of-type(2) {
                width: 10px;
                height: 10px;
                background-color: ${props => props.theme.colors.main};
                top: 20px;
                left: 50px;
                border-radius: 2px;
            }

            &:nth-of-type(3) {
                width: 32px;
                height: 32px;
                border: 4px solid ${props => props.theme.colors.main};
                box-sizing: border-box;
                top: 32px;
                left: 0px;
                border-radius: 6px;
            }
        }
    }

    &.style-2 {
        top: -64px;
        right: -40px;

        span {
            display: block;
            position: absolute;

            &:nth-of-type(1) {
                width: 32px;
                height: 32px;
                background-color: ${props => props.theme.colors.accent};
                top: 0px;
                left: 32px;
                border-radius: 4px;
            }

            &:nth-of-type(2) {
                width: 16px;
                height: 16px;
                background-color: ${props => props.theme.colors.main};
                top: 48px;
                left: 42px;
                border-radius: 2px;
            }

            &:nth-of-type(3) {
                width: 24px;
                height: 24px;
                border: 4px solid ${props => props.theme.colors.main};
                box-sizing: border-box;
                top: 28px;
                left: 0px;
                border-radius: 5px;
            }
        }
    }
`

const SectionTitle: React.FC<TitleProps> = ({
    sectionTitle,
    sectionSubTitle,
    titleAlignement,
    needsPadding,
    className,
    decorated,
    decorationStyle
}) => {
    return (
        <TitleWrapper className={needsPadding ? `${titleAlignement} ${className} mobile-padding` : `${titleAlignement} ${className}`}>
            <h3
                style={
                    titleAlignement === 'center'
                        ? { position: 'relative', width: 'fit-content', margin: 'auto' }
                        : { position: 'relative', width: 'fit-content' }
                }
            >
                <Text value={sectionSubTitle} />
                {decorated ? (
                    <DecorationSquares className={`style-${decorationStyle}`}>
                        <span />
                        <span />
                        <span />
                    </DecorationSquares>
                ) : (
                    <></>
                )}
            </h3>
            <h2>
                <Text value={sectionTitle} />
            </h2>
        </TitleWrapper>
    )
}

export default SectionTitle
