const fonts = {
    fontSizeBackgroundText: '24rem',
    fontSizeBackgroundTextSmaller: '16rem',
    fontSizeModalH2: '3rem',
    fontSizeModalH2Mobile: '2.5rem',
    fontSizeIntermediate: '2.5rem',
    fontSizeNormal: '2rem',
    fontSizeSmall: '14px',
    primaryFont: 'Silka'
}

export default fonts
