import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../assets/icons/amapii.svg'
import { ReactComponent as LogoWhite } from '../../assets/icons/amapii-white.svg'
import CTA from '../common/CTA'
import mediaQuery from '../../styles/media'
import Text from '../common/Text'
import ModalNoForm from '../common/ModalNoForm'
import { useTranslationContext } from '../../hooks/useTranslation'
import utils from '../../utils'
import DynamicIcon from '../common/DynamicIcon'

const NavWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 16px calc((100% - 1128px) / 2);
    justify-content: space-between;
    width: 100%;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 7;
    &.solid {
        background-color: ${props => props.theme.colors.white};
        box-shadow: ${props => props.theme.shadow.classic};

        .tooltip {
            background-color: ${props => props.theme.colors.main};
            color: ${props => props.theme.colors.white};
        }

        .current-lang-wrapper {
            border: 2px solid ${props => props.theme.colors.main};
        }

        .lang-selector-wrapper {
            .select-button {
                background-color: ${props => props.theme.colors.white};
            }
            :hover {
                .select-button {
                    transform: translateY(calc(100% + 4px));
                    :hover {
                        background-color: ${props => props.theme.colors.mainLighter};
                    }
                }
            }
        }
    }

    ${mediaQuery('md')} {
        padding: 16px;
        width: 100%;
    }
`

const CTAWrapper = styled.div`
    display: flex;
    gap: 16px;

    ${mediaQuery('md')} {
        display: none;
    }
`

const TooltipWrapper = styled.div`
    display: flex;
    position: relative;

    :hover {
        span {
            :nth-of-type(1) {
                opacity: 1;
            }
        }
    }
`

const Tooltip = styled.span`
    display: inline-block;
    position: absolute;
    padding: 6px 16px;
    box-sizing: border-box;
    bottom: -24px;
    right: 8px;
    width: 280px;
    font-size: ${props => props.theme.fonts.fontSizeSmall};
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadow.classic};
    opacity: 0;
    transition: all 0.25s ease-in-out;
    pointer-events: none;
    text-align: center;
`

const BurgerCTAWrapper = styled.div`
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
`

const BurgerLinks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`

const BurgerMenu = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    left: 0;
    top: -100vh;
    z-index: -1;
    pointer-events: none;
    padding: 136px 16px 40px 16px;
    box-sizing: border-box;
    transition: top 0.35s 0.45s ease-in-out;
    background-color: ${props => props.theme.colors.white};

    &.open {
        top: 0;
        pointer-events: all;
        transition: top 0.5s ease-in-out;
    }

    .link {
        transform: translateX(0);
        font-size: 2.5rem;

        &.hidden {
            transform: translateX(-150%);
        }
    }
    .hidden-button {
        opacity: 0;
    }
    .displayed-button {
        opacity: 1;
    }

    ${mediaQuery('md', 'min')} {
        display: none;
    }
`

const BurgerButton = styled.span`
    width: 40px;
    height: 40px;
    display: flex;

    .burger-icon {
        display: block;
        position: relative;
        width: 24px;
        height: 3px;
        background-color: ${props => props.theme.colors.white};
        margin: auto;
        transition: height 0s ease-in-out 0.1s;

        &.solid {
            background-color: ${props => props.theme.colors.main};

            :after,
            :before {
                background-color: ${props => props.theme.colors.main};
            }
        }

        :after,
        :before {
            content: '';
            position: absolute;
            display: block;
            width: 24px;
            height: 3px;
            left: 0;
            background-color: ${props => props.theme.colors.white};
            transition-property: top, transform, width, left;
            transition-duration: 0.1s, 0.2s, 0.2s, 0.2s;
            transition-delay: 0.2s, 0s, 0s, 0s;
            transform-origin: center;
            transition-timing-function: ease-in-out;
        }

        :before {
            top: -9px;
        }

        :after {
            top: 9px;
        }
        &.open {
            height: 0;
            ::before,
            ::after {
                transition-delay: 0s, 0.1s, 0.1s, 0.1s;
                width: 32px;
                top: 0;
                left: -4px;
            }
            :before {
                transform: rotate(-45deg);
            }
            :after {
                transform: rotate(45deg);
            }
        }
    }

    ${mediaQuery('md', 'min')} {
        display: none;
    }
`

const CustomLink = styled.a`
    :hover {
        cursor: pointer;
    }
`

const LangSelectorWrapper = styled.div`
    position: relative;
    transition: all 0.25s ease-in-out;
    border-radius: 4px;

    .select-button {
        position: absolute;
        top: 0;
        z-index: -1;

        &.hidden {
            display: none;
        }
    }

    :hover {
        .select-button {
            transform: translateY(calc(100% + 4px));
        }
    }
`

const CurrentLang = styled.div`
    display: flex;
    height: 100%;
    background: ${props => props.theme.colors.white};
    text-transform: uppercase;
    font-weight: bold;
    line-height: 24px;
    gap: 8px;
    width: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`

const BurgerLangSelectorWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 16px;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease-in-out;
    border-radius: 4px;

    .selector-label {
        text-transform: uppercase;
    }

    .selected {
        border: 2px solid ${props => props.theme.colors.main} !important;
    }

    .not-selected {
        border: 2px solid ${props => props.theme.colors.white} !important;
    }
`

const MainNavigation: React.FC = () => {
    const VPHeight = document.documentElement.clientHeight
    const [isOpen, setIsOpen] = useState(false)
    const [isSolid, setIsSolid] = useState(false)
    const [tempSolid, setTempSolid] = useState(false)
    const [show, setShow] = useState(false)
    const [modalType, setModalType] = useState('classic')
    const [currentLanguage, setCurrentLanguage] = useState<string | null>(/^en\b/.test(navigator.language) ? 'en' : 'fr')
    const { changeLanguage } = useTranslationContext()

    useEffect(() => {
        if (utils.storage.getItem('I18N_LANGUAGE') !== null) {
            setCurrentLanguage(utils.storage.getItem('I18N_LANGUAGE'))
        }
    })

    useEffect(() => {
        const scrollHandler = () => {
            const scroll = document.documentElement.scrollTop

            if (scroll > VPHeight - 80) {
                setIsSolid(true)
            } else {
                setIsSolid(false)
            }
        }

        window.addEventListener('scroll', scrollHandler)

        return () => window.removeEventListener('scroll', scrollHandler)
    })

    const openBurger = () => {
        setIsOpen(!isOpen)
        setTempSolid(true)
        setTimeout(() => {
            setTempSolid(false)
        }, 700)
    }

    const closeBurger = () => {
        setIsOpen(false)
        setTempSolid(true)
        setTimeout(() => {
            setTempSolid(false)
        }, 700)
    }

    return (
        <NavWrapper className={isOpen || isSolid || tempSolid ? 'solid' : undefined}>
            <CustomLink
                style={{ height: '40px', zIndex: 5 }}
                onClick={() => {
                    window.scrollTo(0, 0)
                }}
            >
                {isSolid || isOpen || tempSolid ? <Logo style={{ height: 'inherit' }} /> : <LogoWhite style={{ height: 'inherit' }} />}
            </CustomLink>
            <CTAWrapper>
                <TooltipWrapper>
                    <CTA
                        icon="account"
                        iconSize={24}
                        weight="secondary"
                        type="icon"
                        color={isSolid ? 'main' : 'white'}
                        onClick={() => {
                            window.open('https://e.amapii.com/login')
                        }}
                        rounded
                    />
                    <Tooltip className="tooltip">
                        <Text value="navigation.tooltip" />
                    </Tooltip>
                </TooltipWrapper>
                <CTA
                    text="Contact"
                    weight="primary"
                    type="text"
                    color={isSolid ? 'main' : 'white'}
                    onClick={() => {
                        setModalType('classic')
                        setShow(true)
                        document.body.classList.add('prevent_scrolling')
                    }}
                    rounded
                />
                <LangSelectorWrapper className="lang-selector-wrapper">
                    <CurrentLang className="current-lang-wrapper">
                        {currentLanguage === 'en' ? <DynamicIcon icon="UK-flag" size={24} /> : <DynamicIcon icon="france-flag" size={24} />}
                    </CurrentLang>
                    <CTA
                        icon="france-flag"
                        iconSize={24}
                        width="full-width"
                        weight="secondary"
                        type="icon"
                        color={isSolid ? 'main' : 'white'}
                        onClick={() => {
                            changeLanguage('fr')
                            setCurrentLanguage('fr')
                        }}
                        className={`select-button ${currentLanguage === 'fr' ? 'hidden' : ''}`}
                        rounded
                    />
                    <CTA
                        icon="UK-flag"
                        width="full-width"
                        iconSize={24}
                        weight="secondary"
                        type="icon"
                        color={isSolid ? 'main' : 'white'}
                        onClick={() => {
                            changeLanguage('en')
                            setCurrentLanguage('en')
                        }}
                        className={`select-button ${currentLanguage === 'en' ? 'hidden' : ''}`}
                        rounded
                    />
                </LangSelectorWrapper>
            </CTAWrapper>
            <BurgerButton onClick={openBurger}>
                {isSolid || isOpen || tempSolid ? (
                    <span className={isOpen ? 'solid open burger-icon' : 'solid burger-icon'} />
                ) : (
                    <span className={isOpen ? 'open burger-icon' : 'burger-icon'} />
                )}
            </BurgerButton>
            <BurgerMenu className={isOpen ? 'open' : undefined}>
                <BurgerLinks>
                    <CustomLink
                        className={isOpen ? 'link animation-show-link' : 'hidden link animation-hide-link'}
                        href="#aboutUs"
                        onClick={closeBurger}
                    >
                        <Text value="navigation.aboutUs" />
                    </CustomLink>
                    <CustomLink
                        className={isOpen ? 'link animation-show-link' : 'hidden link animation-hide-link'}
                        href="#services"
                        onClick={closeBurger}
                    >
                        <Text value="navigation.services" />
                    </CustomLink>
                    <CustomLink
                        className={isOpen ? 'link animation-show-link' : 'hidden link animation-hide-link'}
                        href="#partners"
                        onClick={closeBurger}
                    >
                        <Text value="navigation.partners" />
                    </CustomLink>
                    <CustomLink
                        className={isOpen ? 'link animation-show-link' : 'hidden link animation-hide-link'}
                        href="#hiring"
                        onClick={closeBurger}
                    >
                        <Text value="navigation.hiring" />
                    </CustomLink>
                    <CustomLink
                        className={isOpen ? 'link animation-show-link' : 'hidden link animation-hide-link'}
                        href="#news"
                        onClick={closeBurger}
                    >
                        <Text value="navigation.news" />
                    </CustomLink>
                </BurgerLinks>
                <BurgerCTAWrapper>
                    {/* <CTA
                        display={isOpen ? 'displayed-button animation-show-button' : 'hidden-button animation-hide-button'}
                        text={<Text value="navigation.eAMA" />}
                        weight="secondary"
                        type="text"
                        color="main"
                        width="full-width"
                        onClick={() => {
                            window.open('https://e.amapii.com/login')
                        }}
                    /> */}
                    <CTA
                        display={isOpen ? 'displayed-button animation-show-button' : 'hidden-button animation-hide-button'}
                        text={<Text value="contactUs" />}
                        weight="primary"
                        type="text"
                        color="main"
                        width="full-width"
                        onClick={() => {
                            setModalType('classic')
                            setShow(true)
                            document.body.classList.add('prevent_scrolling')
                            closeBurger()
                        }}
                        rounded
                    />
                    <BurgerLangSelectorWrapper>
                        {/* <span className="selector-label">
                            <Text value="navigation.choose.lang" />
                        </span> */}
                        <CTA
                            text="FR"
                            icon="france-flag"
                            iconSize={24}
                            weight="tertiary"
                            type="text-and-icon"
                            color="main"
                            onClick={() => {
                                changeLanguage('fr')
                                setCurrentLanguage('fr')
                            }}
                            padding="2px 8px"
                            className={currentLanguage === 'fr' ? 'selected' : 'not-selected'}
                            rounded
                        />
                        <CTA
                            text="EN"
                            icon="UK-flag"
                            iconSize={24}
                            weight="tertiary"
                            type="text-and-icon"
                            color="main"
                            onClick={() => {
                                changeLanguage('en')
                                setCurrentLanguage('en')
                            }}
                            padding="2px 8px"
                            className={currentLanguage === 'en' ? 'selected' : 'not-selected'}
                            rounded
                        />
                    </BurgerLangSelectorWrapper>
                </BurgerCTAWrapper>
            </BurgerMenu>
            <ModalNoForm show={show} setShow={setShow} modalType={modalType} />
        </NavWrapper>
    )
}

export default MainNavigation
