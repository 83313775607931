const brandColors = {
    black: '#080D0F',
    blackRGBA: 'rgba(8, 13, 15, 1)',
    blackRGBAtransparent: 'rgba(8, 13, 15, 0)',
    blackRGBHalf: 'rgba(8, 13, 15, 0.5)',
    gray75: '#2e2e2c',
    gray60: '#666666',
    gray50: '#7f7f7f',
    gray40: '#999999',
    gray30: '#b2b2b2',
    gray20: '#cccccc',
    gray10: '#e5e5e5',
    gray5: '#ebebea',
    white: '#fcfcfc',
    white50percent: 'rgba(252, 252, 252, 0.5)',
    accent: '#c7a948',
    accent20percent: 'rgba(201, 166, 64, 0.2)',
    main: '#17384b',
    mainLighter: '#1F7091',
    main20percent: 'rgba(0, 56, 79, 0.2)',
    background: '#f5f4f1'
}

const colors = {
    ...brandColors,
    text: ''
}

export default colors
