import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import mediaQuery from '../../styles/media'
import CTA from './CTA'

interface SliderProps {
    slidesList: ReactElement[]
    className?: string
}

const SliderContainer = styled.div`
    display: flex;
    height: fit-content;
    position: relative;
    padding: 40px 0 40px 0;
    flex-direction: column;
`

const SliderWrapper = styled.div`
    position: relative;
    height: fit-content;
    filter: drop-shadow(${props => props.theme.shadow.thirdLevel});

    :before {
        content: '';
        position: absolute;
        background-color: ${props => props.theme.colors.accent20percent};
        width: 15vw;
        height: 15vw;
        min-width: 200px;
        min-height: 200px;
        max-width: 400px;
        max-height: 400px;
        transform: rotate(-120deg);
        bottom: calc(-5vw + 40px);
        right: calc(156px + 10vw);
        box-sizing: border-box;
    }

    .maskingWrapper {
        padding: 0 calc((100% - 1128px) / 2);
        mask-image: linear-gradient(to left, transparent 24px, black 120px, black calc(100% - 120px), transparent calc(100% - 24px));
        overflow-x: hidden;
    }
`

const PrevButtonWrapper = styled.span`
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 40px;
    z-index: 1;

    a {
        margin: auto;
    }

    ${mediaQuery('xl')} {
        left: 0;
        scale: 0.75;
    }
`

const NextButtonWrapper = styled.span`
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 40px;
    z-index: 1;

    a {
        margin: auto;
    }

    ${mediaQuery('xl')} {
        right: 0;
        scale: 0.75;
    }
`

const SlidesWrapper = styled.div`
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    height: fit-content;
    width: fit-content;
    transform: translateX(-40px);
    align-items: center;
    transition: all 1s ease-in-out;
    gap: 24px;
    padding: 40px;
`

const ClassicSlider: React.FC<SliderProps> = ({ slidesList, className }) => {
    const [index, setIndex] = useState(0)
    const slidesWrapper: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
    const [translationBreakpoints, setTranslationBreakpoints] = useState<number[]>([])

    useEffect(() => {
        if (typeof slidesWrapper.current === 'object' && slidesWrapper.current != null && translationBreakpoints.length === 0) {
            const wrapperWidth = slidesWrapper.current.clientWidth - 440
            for (let i = 0; i <= Math.floor(slidesList.length / 3); i += 1) {
                let breakpointValue = 0
                if (i !== Math.floor(slidesList.length / 3)) {
                    breakpointValue = Math.abs(wrapperWidth / (slidesList.length - 1)) * (3 * i) + 40
                } else if (slidesList.length % 3 !== 0) {
                    breakpointValue = Math.abs(
                        translationBreakpoints[i - 1] + (wrapperWidth / (slidesList.length - 1)) * (slidesList.length % 3)
                    )
                } else {
                    setTranslationBreakpoints(translationBreakpoints.slice(0, Math.floor(slidesList.length / 3)))
                    return
                }
                translationBreakpoints.push(breakpointValue)
            }
        }
    }, [])

    const nextSlide = () => {
        if (typeof slidesWrapper.current === 'object' && slidesWrapper.current != null && index < translationBreakpoints.length - 1) {
            slidesWrapper.current.style.transform = `translateX(${-translationBreakpoints[index + 1]}px)`
            setIndex(index + 1)
        }
    }

    const prevSlide = () => {
        if (typeof slidesWrapper.current === 'object' && slidesWrapper.current != null && index > 0) {
            slidesWrapper.current.style.transform = `translateX(${-translationBreakpoints[index - 1]}px)`
            setIndex(index - 1)
        }
    }

    return (
        <SliderContainer className={className}>
            <SliderWrapper>
                <PrevButtonWrapper className={index > 0 ? '' : 'no-display'}>
                    <CTA
                        onClick={() => {
                            prevSlide()
                        }}
                        type="icon"
                        icon="chevronLeft"
                        weight="primary"
                        color="main"
                        iconSize={32}
                        padding="8px"
                        rounded
                    />
                </PrevButtonWrapper>
                <NextButtonWrapper
                    className={
                        index === Math.floor(slidesList.length / 3) ||
                        (slidesList.length % 3 === 0 && index === Math.floor(slidesList.length / 3) - 1)
                            ? 'no-display'
                            : ''
                    }
                >
                    <CTA
                        onClick={() => {
                            nextSlide()
                        }}
                        type="icon"
                        icon="chevronRight"
                        weight="primary"
                        color="main"
                        iconSize={32}
                        padding="8px"
                        rounded
                    />
                </NextButtonWrapper>
                <div className="maskingWrapper">
                    <SlidesWrapper ref={slidesWrapper}>{slidesList}</SlidesWrapper>
                </div>
            </SliderWrapper>
        </SliderContainer>
    )
}

export default ClassicSlider
