import colors from './colors'
import fonts from './fonts'
import { blur, shadow } from './effects'

const styles = {
    colors,
    fonts,
    shadow,
    blur
}

export default styles
