export const shadow = {
    classic: '0px 16px 96px rgba(0, 0, 0, 0.06)',
    invertedClassic: '0px -16px 96px rgba(0, 0, 0, 0.06)',
    newsTile: '0px 8px 80px rgba(0, 0, 0, 0.15)',
    firstLevel: '0px 8px 80px rgba(0, 0, 0, 0.04)',
    secondLevel: '0px 4px 64px rgba(0, 0, 0, 0.06)',
    thirdLevel: '0px 8px 80px rgba(0, 0, 0, 0.1)',
    inset: 'inset 8px 8px 24px rgba(0, 0, 0, 0.08)'
}

export const blur = {
    classic: 'blur(8px)'
}
