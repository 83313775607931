import React, { ReactElement } from 'react'
import styled from 'styled-components'
import ClassicSlider from '../common/ClassicSlider'
import SectionTitle from '../common/SectionTitle'
import instaPost from '../../assets/images/instaPost.jpg'
import instaPost2 from '../../assets/images/instaPost2.jpg'
import FBPost1 from '../../assets/images/FB_POST1.jpg'
import FBPost2 from '../../assets/images/FB_POST2.jpg'
import DynamicIcon from '../common/DynamicIcon'
import MobileSlider from '../common/MobileSlider'
import mediaQuery from '../../styles/media'

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    h2 {
        color: ${props => props.theme.colors.main};
    }

    .mobile-padding {
        padding: 0 16px 24px 16px;
    }

    ${mediaQuery('md')} {
        height: fit-content;
        padding: 80px 0;
    }
`

const SlideWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 360px;
    height: 360px;
    flex-shrink: 0;
    background-color: ${props => props.theme.colors.white};
    border-radius: 8px;
    overflow: hidden;

    &.instagram {
        justify-content: flex-end;

        h5 {
            color: ${props => props.theme.colors.white};
            margin: 0;
        }

        :hover {
            cursor: pointer;

            .instagramContent {
                transform: translateY(0);
            }
        }
    }

    &.facebook,
    &.linkedIn {
        .bottom-fade {
            ::after {
                opacity: 1;
            }
        }
        img {
            height: 200px;
        }
        :hover {
            img {
                height: 136px;
            }
            cursor: pointer;
            .bottom-fade {
                ::after {
                    opacity: 0;
                }
            }
        }
    }

    ${mediaQuery('md')} {
        width: calc(100vw - 32px);
    }
`

const SlideCoverImage = styled.img`
    display: flex;
    width: 100%;
    height: 136px;
    object-fit: cover;
    transition: all ease-in-out 0.25s;
    object-position: left 35%;
`

const SlideFullImage = styled.img`
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
`

const SlidinContentWrapper = styled.div`
    display: flex;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: column;

    &.bottom-fade {
        ::after {
            content: '';
            display: block;
            position: absolute;
            opacity: 0;
            width: 360px;
            height: 100%;
            bottom: -16px;
            left: -16px;
            background: linear-gradient(to top, ${props => props.theme.colors.white} 64px, transparent);
            transition: all 0.25s ease-in-out;
        }
    }
`

const SlideContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 16px 16px 16px;
    box-sizing: border-box;
    justify-content: space-between;

    p {
        font-size: ${props => props.theme.fonts.fontSizeSmall};
        line-height: 16px;
        margin: 0;

        .preview {
            height: 48px;
            overflow: hidden;
        }
    }

    h6 {
        margin: 0 0 16px 0;
    }

    h5 {
        margin: 0;
    }

    :hover {
        cursor: pointer;
    }

    &.instagramContent {
        position: absolute;
        width: 100%;
        justify-content: flex-start;
        background-color: rgba(0, 0, 0, 0.75);
        height: fit-content;
        padding: 8px 16px 16px 16px;
        gap: 16px;
        bottom: 0;
        transform: translateY(calc(100% - 56px));
        transition: all 0.25s ease-in-out;

        :before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 56px;
            top: -56px;
            left: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
        }

        p {
            color: ${props => props.theme.colors.white};
        }

        div {
            position: relative;
            padding: 0;
            bottom: 0;
        }
    }
`

const SlideMetadata = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 2;
    align-items: center;
    margin-bottom: 0;
    position: absolute;
    bottom: 16px;
    left: 0;
    padding: 0 16px;
    width: 100%;
`

const HashtagWrapper = styled.span`
    display: flex;
    height: fit-content;
    gap: 8px;
`

const slidesList: ReactElement[] = [
    <SlideWrapper
        onClick={() => {
            window.open(
                'https://www.linkedin.com/posts/amapii_bonneannaeze2024-amapii-collaboration-activity-7147986619831054337-VTlM?utm_source=share&utm_medium=member_desktop'
            )
        }}
        className="linkedIn"
        key={0}
    >
        <SlideCoverImage src="https://media.licdn.com/dms/image/D4E22AQF_XR_caKJ4WQ/feedshare-shrink_1280/0/1704212812415?e=1709164800&v=beta&t=9Ms-NFqyqKoTmB1G9cnM4S23BjcHSTMJGTOnM3DkRnk" />
        <SlideContent>
            <SlidinContentWrapper className="bottom-fade">
                <h6>🎉✨ Bonne année #2024 ! 🎉✨</h6>
                <p className="preview">
                    Alors que nous clôturons une belle année, nous voulons prendre un moment pour remercier nos collaborateurs, nos
                    consultants et nos clients pour leur confiance.
                </p>
            </SlidinContentWrapper>
            <SlideMetadata>
                <DynamicIcon
                    onClick={() => {
                        window.open('https://www.linkedin.com/company/80533008/')
                    }}
                    icon="linkedin"
                    size={32}
                    iconColor="#2867B2"
                />
                <HashtagWrapper>
                    <h5>#BonneAnnée2024</h5>
                    <h5>#Collaboration</h5>
                </HashtagWrapper>
            </SlideMetadata>
        </SlideContent>
    </SlideWrapper>,
    <SlideWrapper
        onClick={() => {
            window.open('https://www.instagram.com/p/C1w_x4prc-Q/')
        }}
        className="instagram"
        key={1}
    >
        <SlideFullImage src={instaPost2} />
        <SlidinContentWrapper>
            <SlideContent className="instagramContent">
                <SlideMetadata>
                    <DynamicIcon
                        onClick={() => {
                            window.open('https://www.instagram.com/amap2i/?hl=fr')
                        }}
                        icon="instagram"
                        size={32}
                        iconColor="white"
                    />
                    <HashtagWrapper>
                        <h5>#Épiphanie</h5>
                        <h5>#GaletteDesRois</h5>
                    </HashtagWrapper>
                </SlideMetadata>
                <p className="preview">
                    Photo de profil de amap2i 👑✨ EVENEMENT ! #Epiphanie 2024 👑✨ Jeudi midi, nous avons célébré l'Épiphanie chez Amap2i !
                    Au menu : frangipane et pâte à tartiner pour régaler tous les goûts. Notre Roi et Reine 2024 : Jean-Marc et Eva ! Que
                    leur règne soit aussi doux que nos galettes ! 🤴👸
                </p>
            </SlideContent>
        </SlidinContentWrapper>
    </SlideWrapper>,
    <SlideWrapper
        onClick={() => {
            window.open(
                'https://www.linkedin.com/posts/amapii_amapii-itjobs-recrutement-activity-7137802798372384768-Z8Ma?utm_source=share&utm_medium=member_desktop'
            )
        }}
        className="linkedIn"
        key={3}
    >
        <SlideCoverImage src="https://media.licdn.com/dms/image/D4E22AQGBWJWQYPdj0g/feedshare-shrink_800/0/1701722562008?e=1709164800&v=beta&t=PB05Iv_s1gcg6rwHPJt8WjayfRVVg_jwWzPKCyJvkRE" />
        <SlideContent>
            <SlidinContentWrapper className="bottom-fade">
                <h6>AMAPII recrute ! 🔍</h6>
                <p className="preview">
                    Nous sommes actuellement à la recherche de plusieurs profils en France :
                    <br />
                    <br />
                    🌟ARCHITECTE SYSTEME,📍92
                    <br />
                    🌟BUSINESS ANALYST,📍98
                    <br />
                    🌟RESPONSABLE SUPPORT📍69
                </p>
            </SlidinContentWrapper>
            <SlideMetadata>
                <DynamicIcon
                    onClick={() => {
                        window.open('https://www.linkedin.com/company/80533008/')
                    }}
                    icon="linkedin"
                    size={32}
                    iconColor="#2867B2"
                />
                <HashtagWrapper>
                    <h5>#digitalmarketing</h5>
                    <h5>#relationclients</h5>
                </HashtagWrapper>
            </SlideMetadata>
        </SlideContent>
    </SlideWrapper>,
    <SlideWrapper
        onClick={() => {
            window.open('https://www.facebook.com/amap2i/posts/pfbid04WD12fPzdDEfLqkCT4JSntGWDkoM3xcFwYimse1SPhecfUB5trjQmT8AA7VuUf84l')
        }}
        className="facebook"
        key={2}
    >
        <SlideCoverImage src={FBPost1} />
        <SlideContent>
            <SlidinContentWrapper className="bottom-fade">
                <h6>🍇🍷 EVENEMENT I Soirée du #BeaujolaisNouveau2023 🍷🍇</h6>
                <p className="preview">
                    Une belle soirée chez Pépita pour célébrer le Beaujolais Nouveau ! ✨ Notre objectif ? Découvrir le Beaujolais Nouveau
                    2023, bravo à Yorgos qui a remporté le titre pour cette troisième édition. 🏆👏
                </p>
            </SlidinContentWrapper>
            <SlideMetadata>
                <DynamicIcon
                    onClick={() => {
                        window.open('https://www.facebook.com/amap2i')
                    }}
                    icon="facebook"
                    size={32}
                    iconColor="#1877F2"
                />
                <HashtagWrapper>
                    <h5>#BrandNew</h5>
                    <h5>#Workplace</h5>
                    <h5>#Teamwork</h5>
                </HashtagWrapper>
            </SlideMetadata>
        </SlideContent>
    </SlideWrapper>,
    <SlideWrapper
        onClick={() => {
            window.open('https://www.instagram.com/p/Cxa57DnoPA0/?utm_source=ig_web_copy_link◊')
        }}
        className="instagram"
        key={4}
    >
        <SlideFullImage src={instaPost} />
        <SlidinContentWrapper>
            <SlideContent className="instagramContent">
                <SlideMetadata>
                    <DynamicIcon
                        onClick={() => {
                            window.open('https://www.instagram.com/amap2i/?hl=fr')
                        }}
                        icon="instagram"
                        size={32}
                        iconColor="white"
                    />
                    <HashtagWrapper>
                        <h5>#SEDD</h5>
                        <h5>#MainVerte</h5>
                        <h5>#Potager</h5>
                    </HashtagWrapper>
                </SlideMetadata>
                <p className="preview">
                    Pour célébrer le début de la Semaine Européenne du Développement Durable (SEDD), nous avons transformé notre ancienne
                    baignoire en un jardin potager. 🌍🛁
                </p>
            </SlideContent>
        </SlidinContentWrapper>
    </SlideWrapper>,
    <SlideWrapper
        onClick={() => {
            window.open('https://www.facebook.com/amap2i/posts/pfbid02AR1vkNyHWEGHgDf6PdjbhP2KRW6GfyXoeyoZSd9EeNMoHWHzm2GwWP1Tmu6CsNELl')
        }}
        className="facebook"
        key={5}
    >
        <SlideCoverImage src={FBPost2} />
        <SlideContent>
            <SlidinContentWrapper className="bottom-fade">
                <h6>🎉 AMAPII accueille ses nouveaux alternants ! 🚀</h6>
                <p className="preview">
                    Bienvenue à bord aux alternants qui rejoignent notre équipe pour participer à notre aventure humaine et professionnelle.
                    🤗
                </p>
            </SlidinContentWrapper>
            <SlideMetadata>
                <DynamicIcon
                    onClick={() => {
                        window.open('https://www.facebook.com/amap2i')
                    }}
                    icon="facebook"
                    size={32}
                    iconColor="#1877F2"
                />
            </SlideMetadata>
        </SlideContent>
    </SlideWrapper>
]

const LastNews: React.FC = () => {
    return (
        <SectionWrapper id="news">
            <SectionTitle
                className="no-mobile-display padding"
                sectionTitle="news.title"
                sectionSubTitle="news.subtitle"
                titleAlignement="center"
                decorated
                decorationStyle={1}
            />
            <SectionTitle
                className="no-desk-display mobile-padding"
                sectionTitle="news.title"
                sectionSubTitle="news.subtitle"
                titleAlignement="left"
            />
            <ClassicSlider className="no-mobile-display" slidesList={slidesList} />
            <MobileSlider className="no-desk-display" slidesList={slidesList} />
        </SectionWrapper>
    )
}

export default LastNews
