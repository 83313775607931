import React, { useState, TouchEvent, useRef, ReactElement, useEffect } from 'react'
import styled from 'styled-components'
import mediaQuery from '../../styles/media'

interface MobileSliderProps {
    slidesList: ReactElement[]
    className?: string
    margin?: number
}

const SliderWrapper = styled.div`
    display: flex;
    overflow-x: clip;
    width: 100%;

    ${mediaQuery('md', 'min')} {
        display: none;
    }
`

const SlidesWrapper = styled.div`
    display: flex;
    padding: 0 16px;
    gap: 16px;
    filter: drop-shadow(${props => props.theme.shadow.newsTile});
`

const SliderTracker = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
    z-index: 10;
`

const SliderTrackerElement = styled.div<{ current: boolean }>`
    display: flex;
    background-color: ${props => (props.current ? props.theme.colors.accent : props.theme.colors.main20percent)};
    height: 4px;
    width: 24px;
    border-radius: 8px;
`

const MobileSlider: React.FC<MobileSliderProps> = ({ slidesList, className, margin }) => {
    const [touchPos, setTouchPos] = useState(0)
    const [currentXTranslation, setCurrentXTranslation] = useState(0)
    const [tempTranslation, setTempTranslation] = useState(0)
    const slidesWrapper: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
    const [translationBreakpoints, setTranslationBreakpoints] = useState<number[]>([])
    const [slideIndex, setSlideIndex] = useState(0)
    const trackerElementsList = slidesList.map(item => (
        <SliderTrackerElement current={slidesList.indexOf(item) === slideIndex} key={item.key} />
    ))

    useEffect(() => {
        if (
            typeof slidesWrapper.current === 'object' &&
            slidesWrapper.current != null &&
            translationBreakpoints.length < slidesList.length
        ) {
            const offsetLimit = margin
                ? -(slidesWrapper.current.clientWidth - (document.documentElement.clientWidth - margin * 2))
                : -(slidesWrapper.current.clientWidth - document.documentElement.clientWidth)
            for (let i = 0; i < slidesList.length; i += 1) {
                const breakpointValue = Math.abs((offsetLimit / (slidesList.length - 1)) * i)
                translationBreakpoints.push(breakpointValue)
            }
        }
    }, [])

    const sliding = (offsetValue: number) => {
        if (typeof slidesWrapper.current === 'object' && slidesWrapper.current != null) {
            const offsetLimit = margin
                ? -(slidesWrapper.current.clientWidth - (document.documentElement.clientWidth - margin * 2))
                : -(slidesWrapper.current.clientWidth - document.documentElement.clientWidth)

            if (offsetValue > offsetLimit && offsetValue < 0) {
                slidesWrapper.current.style.transform = `translateX(${offsetValue}px)`
                setTempTranslation(offsetValue)
            } else if (offsetValue <= offsetLimit) {
                slidesWrapper.current.style.transform = `translateX(${offsetLimit}px)`
                setTempTranslation(offsetLimit)
            } else {
                slidesWrapper.current.style.transform = `translateX(0px)`
                setTempTranslation(0)
            }
        }
    }

    const handleTouchStart = (e: TouchEvent) => {
        const initialPos = e.touches[0].clientX
        setTouchPos(initialPos)
    }

    const handleTouchMove = (e: TouchEvent) => {
        const initialPos = touchPos
        const currentPos = e.touches[0].clientX
        const processedPos = currentPos - initialPos + currentXTranslation
        sliding(processedPos)
    }

    const adjustPosition = () => {
        if (
            typeof slidesWrapper.current === 'object' &&
            slidesWrapper.current != null &&
            Math.abs(tempTranslation - currentXTranslation) !== Math.abs(currentXTranslation)
        ) {
            if (tempTranslation - currentXTranslation < 0 && slideIndex < slidesList.length - 1) {
                if (tempTranslation - currentXTranslation < -50) {
                    slidesWrapper.current.style.transition = `transform 0.25s ease-in-out`
                    setTimeout(() => {
                        if (typeof slidesWrapper.current === 'object' && slidesWrapper.current != null) {
                            slidesWrapper.current.style.transition = `transform 0s ease-in-out`
                        }
                    }, 250)
                    slidesWrapper.current.style.transform = `translateX(${-translationBreakpoints[slideIndex + 1]}px)`
                    setCurrentXTranslation(-translationBreakpoints[slideIndex + 1])
                    setSlideIndex(slideIndex + 1)
                    setTempTranslation(0)
                } else if (tempTranslation - currentXTranslation !== 0) {
                    slidesWrapper.current.style.transition = `transform 0.25s ease-in-out`
                    setTimeout(() => {
                        if (typeof slidesWrapper.current === 'object' && slidesWrapper.current != null) {
                            slidesWrapper.current.style.transition = `transform 0s ease-in-out`
                        }
                    }, 250)
                    slidesWrapper.current.style.transform = `translateX(${-translationBreakpoints[slideIndex]}px)`
                    setCurrentXTranslation(-translationBreakpoints[slideIndex])
                    setSlideIndex(slideIndex)
                    setTempTranslation(0)
                }
            } else if (tempTranslation - currentXTranslation > 0 && slideIndex > 0 && tempTranslation - currentXTranslation !== 0) {
                if (tempTranslation - currentXTranslation > 50) {
                    slidesWrapper.current.style.transition = `transform 0.25s ease-in-out`
                    setTimeout(() => {
                        if (typeof slidesWrapper.current === 'object' && slidesWrapper.current != null) {
                            slidesWrapper.current.style.transition = `transform 0s ease-in-out`
                        }
                    }, 250)
                    slidesWrapper.current.style.transform = `translateX(${-translationBreakpoints[slideIndex - 1]}px)`
                    setCurrentXTranslation(-translationBreakpoints[slideIndex - 1])
                    setSlideIndex(slideIndex - 1)
                    setTempTranslation(0)
                } else {
                    slidesWrapper.current.style.transition = `transform 0.25s ease-in-out`
                    setTimeout(() => {
                        if (typeof slidesWrapper.current === 'object' && slidesWrapper.current != null) {
                            slidesWrapper.current.style.transition = `transform 0s ease-in-out`
                        }
                    }, 250)
                    slidesWrapper.current.style.transform = `translateX(${-translationBreakpoints[slideIndex]}px)`
                    setCurrentXTranslation(-translationBreakpoints[slideIndex])
                    setSlideIndex(slideIndex)
                    setTempTranslation(0)
                }
            }
        }
    }

    return (
        <span className={className}>
            <SliderWrapper>
                <SlidesWrapper
                    ref={slidesWrapper}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={adjustPosition}
                >
                    {slidesList}
                </SlidesWrapper>
            </SliderWrapper>
            <SliderTracker>{trackerElementsList}</SliderTracker>
        </span>
    )
}

export default MobileSlider
