import React from 'react'
import { ThemeProvider } from 'styled-components'
import styles from './styles'
import { TranslationProvider } from './i18n'
import Router from './router'

const App: React.FC = () => {
    return (
        <ThemeProvider theme={styles}>
            <TranslationProvider>
                <Router />
            </TranslationProvider>
        </ThemeProvider>
    )
}

export default App
