import React, { useEffect, useState } from 'react'

interface ObserverOptions {
    root: HTMLDivElement | null
    rootMargin: string
    threshold: number
}

function useDisplayCheck(observedSection: React.RefObject<HTMLDivElement>, options: ObserverOptions): boolean {
    const [isVisible, setIsVisible] = useState(false)
    const callbackFunction = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options)
        if (observedSection.current) {
            observer.observe(observedSection.current)
        }

        return () => {
            if (observedSection.current) {
                observer.unobserve(observedSection.current)
            }
        }
    }, [observedSection, options])
    return isVisible
}

export default useDisplayCheck
