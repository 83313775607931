import React, { useEffect, useState, useRef, RefObject } from 'react'
import styled from 'styled-components'
import mediaQuery from '../../styles/media'
import Text from '../common/Text'
import { useTranslationContext } from '../../hooks/useTranslation'

const NavWrapper = styled.div`
    display: flex;
    padding: 8px;
    position: fixed;
    bottom: 8px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 6;
    width: fit-content;
    height: fit-content;
    justify-content: center;
    gap: 8px;
    color: ${props => props.theme.colors.main};
    background-color: ${props => props.theme.colors.white50percent};
    backdrop-filter: blur(8px);
    box-shadow: ${props => props.theme.shadow.classic};
    border-radius: 8px;

    &.locked-pos {
        position: absolute;
        bottom: 312px;
    }

    ${mediaQuery('md')} {
        display: none !important;
    }
`

const NavItem = styled.a`
    display: flex;
    width: fit-content;
    height: fit-content;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 16px;
    color: inherit;
    opacity: 0.7;
    font-size: ${props => props.theme.fonts.fontSizeNormal};
    line-height: 1em;
    transition: all 0.25s ease-in-out;
    cursor: default;

    &.active {
        font-weight: bold;
        opacity: 1;
        color: ${props => props.theme.colors.white};

        :after {
            bottom: 0;
        }
    }

    :not(.active):hover {
        opacity: 1;
        font-weight: 500;
        cursor: pointer;

        :after {
            background-color: ${props => props.theme.colors.white};
            bottom: -4px;
            transition: bottom 0.25s ease-in-out, background-color 0s ease-in-out;
        }
    }
`

const NavTracker = styled.span<{ position: number; width: number; transition: boolean }>`
    display: block;
    position: absolute;
    left: ${props => props.position}px;
    width: ${props => props.width}px;
    height: 48px;
    background: ${props => props.theme.colors.main};
    z-index: -1;
    border-radius: 4px;
    transition: ${props => (props.transition ? 'all 0.3s ease-in-out' : 'transition: all 0s')};
`

function scrollTo(target: RefObject<HTMLAnchorElement | null>) {
    if (target.current != null && !target.current.classList.contains('active')) {
        const targetElement = target.current.getAttribute('href')?.slice(1)
        if (targetElement != null) {
            document.getElementById(targetElement)?.scrollIntoView()
        }
    }
}

const SectionNavigation: React.FC = () => {
    const VPHeight = document.documentElement.clientHeight
    const { t, language } = useTranslationContext()
    const DocHeightWithoutFooter = document.documentElement.offsetHeight - 304
    const [isShown, setIsShown] = useState(false)
    const [isAtFooter, setIsAtFooter] = useState(false)
    const [wrapperClass, setWrapperClass] = useState('no-display')
    const [currentSection, setCurrentSection] = useState('aboutUs')
    const aboutUsLink = useRef<HTMLAnchorElement>(null)
    const servicesLink = useRef<HTMLAnchorElement>(null)
    const partnersLink = useRef<HTMLAnchorElement>(null)
    const hiringLink = useRef<HTMLAnchorElement>(null)
    const newsLink = useRef<HTMLAnchorElement>(null)
    const [trackerOffset, setTrackerOffset] = useState<number>(8)
    const [trackerWidth, setTrackerWidth] = useState<number>(178)
    const [transition, setTransition] = useState(true)

    useEffect(() => {
        const scrollHandler = () => {
            const scroll = document.documentElement.scrollTop

            if (scroll >= 600) {
                setIsShown(true)
            } else {
                setIsShown(false)
            }

            if (scroll >= 600 && scroll <= VPHeight + 512) {
                setIsShown(true)
                setIsAtFooter(false)
                setCurrentSection('aboutUs')
            } else if (scroll >= 600 && scroll <= DocHeightWithoutFooter - VPHeight) {
                setIsShown(true)
                setIsAtFooter(false)
                if (scroll <= DocHeightWithoutFooter - VPHeight && scroll > DocHeightWithoutFooter - 1.75 * VPHeight) {
                    setCurrentSection('news')
                } else if (scroll < DocHeightWithoutFooter - 1.75 * VPHeight && scroll > DocHeightWithoutFooter - 2.75 * VPHeight) {
                    setCurrentSection('hiring')
                } else if (scroll < DocHeightWithoutFooter - 2.75 * VPHeight && scroll > DocHeightWithoutFooter - 3.75 * VPHeight) {
                    setCurrentSection('partners')
                } else if (scroll < DocHeightWithoutFooter - 3.75 * VPHeight && scroll > DocHeightWithoutFooter - 11 * VPHeight) {
                    setCurrentSection('services')
                } else {
                    setCurrentSection('aboutUs')
                }
            } else {
                setIsAtFooter(true)
                setIsShown(true)
            }

            if (isShown && !isAtFooter) {
                setWrapperClass('color-link')
            } else if (isShown && isAtFooter) {
                setWrapperClass('locked-pos')
            } else {
                setWrapperClass('no-display')
            }
        }

        window.addEventListener('scroll', scrollHandler)

        return () => window.removeEventListener('scroll', scrollHandler)
    })

    useEffect(() => {
        setTransition(false)
    }, [language])

    useEffect(() => {
        if (currentSection === 'aboutUs') {
            setTrackerOffset(aboutUsLink?.current?.offsetLeft ? aboutUsLink.current.offsetLeft : 8)
            setTrackerWidth(aboutUsLink?.current?.clientWidth ? aboutUsLink.current.clientWidth : 178)
        } else if (currentSection === 'services') {
            setTrackerOffset(servicesLink?.current?.offsetLeft ? servicesLink.current.offsetLeft : 8)
            setTrackerWidth(servicesLink?.current?.clientWidth ? servicesLink.current.clientWidth : 178)
        } else if (currentSection === 'partners') {
            setTrackerOffset(partnersLink?.current?.offsetLeft ? partnersLink.current.offsetLeft : 8)
            setTrackerWidth(partnersLink?.current?.clientWidth ? partnersLink.current.clientWidth : 178)
        } else if (currentSection === 'hiring') {
            setTrackerOffset(hiringLink?.current?.offsetLeft ? hiringLink.current.offsetLeft : 8)
            setTrackerWidth(hiringLink?.current?.clientWidth ? hiringLink.current.clientWidth : 178)
        } else if (currentSection === 'news') {
            setTrackerOffset(newsLink?.current?.offsetLeft ? newsLink.current.offsetLeft : 8)
            setTrackerWidth(newsLink?.current?.clientWidth ? newsLink.current.clientWidth : 178)
        } else {
            setTrackerOffset(8)
            setTrackerWidth(0)
        }
        setTimeout(() => {
            setTransition(true)
        }, 1000)
    }, [currentSection, language, isShown, aboutUsLink])

    return (
        <NavWrapper className={wrapperClass}>
            <NavItem
                ref={aboutUsLink}
                href="#aboutUs"
                className={currentSection === 'aboutUs' ? 'active' : ''}
                onClick={event => {
                    event?.preventDefault()
                    scrollTo(aboutUsLink)
                }}
            >
                <Text value="navigation.aboutUs" />
            </NavItem>
            <NavItem
                ref={servicesLink}
                href="#services"
                className={currentSection === 'services' ? 'active' : ''}
                onClick={event => {
                    event?.preventDefault()
                    scrollTo(servicesLink)
                }}
            >
                <Text value="navigation.services" />
            </NavItem>
            <NavItem
                ref={partnersLink}
                href="#partners"
                className={currentSection === 'partners' ? 'active' : ''}
                onClick={event => {
                    event?.preventDefault()
                    scrollTo(partnersLink)
                }}
            >
                <Text value="navigation.partners" />
            </NavItem>
            <NavItem
                ref={hiringLink}
                href="#hiring"
                className={currentSection === 'hiring' ? 'active' : ''}
                onClick={event => {
                    event?.preventDefault()
                    scrollTo(hiringLink)
                }}
            >
                <Text value="navigation.hiring" />
            </NavItem>
            <NavItem
                ref={newsLink}
                href="#news"
                className={currentSection === 'news' ? 'active' : ''}
                onClick={event => {
                    event?.preventDefault()
                    scrollTo(newsLink)
                }}
            >
                <Text value="navigation.news" />
            </NavItem>
            <NavTracker position={trackerOffset} width={trackerWidth} transition={transition} />
        </NavWrapper>
    )
}

export default SectionNavigation
