import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from './Text'
import CTA from './CTA'
import mediaQuery from '../../styles/media'
import { LangKeys } from '../../i18n/types'
import DynamicIcon from './DynamicIcon'
import { ReactComponent as USAFlag } from '../../assets/icons/USAFlag.svg'
import { ReactComponent as FranceFlag } from '../../assets/icons/FranceFlag.svg'
import { ReactComponent as BelgiumFlag } from '../../assets/icons/BelgianFlag.svg'
import { ReactComponent as LuxembourgFlag } from '../../assets/icons/LuxFlag.svg'
import { ReactComponent as SwitzerlandFlag } from '../../assets/icons/SwitzFlag.svg'
import MobileSlider from './MobileSlider'
import SectionTitle from './SectionTitle'

interface ModalProps {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    modalType: string
}

const ModalWrapper = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${props => props.theme.colors.blackRGBHalf};
    z-index: 999;
    padding: 80px;
    backdrop-filter: blur(10px);

    ${mediaQuery('md')} {
        padding: 0;
        width: 100%;
    }
`

const ModalHeader = styled.div`
    background-color: ${props => props.theme.colors.main};
    padding: 16px 40px;

    h2 {
        color: ${props => props.theme.colors.white};
        font-size: ${props => props.theme.fonts.fontSizeModalH2};
    }

    ${mediaQuery('md')} {
        h2 {
            font-size: ${props => props.theme.fonts.fontSizeModalH2Mobile};
        }
    }
`

const ContentWrapper = styled.div`
    display: flex;
    position: relative;
    margin: auto;
    width: fit-content;
    height: fit-content;

    .close-button {
        position: absolute;
        top: -48px;
        right: 0;

        ${mediaQuery('mdHeight', 'max', 'height')} {
            right: -48px;
            top: 0;
        }
    }

    ${mediaQuery('md')} {
        width: 100%;
        .close-button {
            top: 8px;
            right: 24px;
            z-index: 5;
        }
    }
`

const ModalContent = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin: auto;
    width: 960px;
    height: fit-content;
    overflow: scroll;
    background-color: ${props => props.theme.colors.background};
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    border-radius: 8px;
    overflow: hidden;

    ${mediaQuery('md')} {
        width: 100%;
        margin: 0 16px;

        .slider-wrapper {
            padding: 40px 0;
        }
    }
`

const ContactInfoWrapper = styled.div`
    width: fit-content;
    display: flex;
    gap: 8px;
    height: 24px;

    a {
        display: inherit;
        gap: inherit;
        font-weight: normal;
        line-height: 2rem;
        align-items: center;
    }

    :nth-of-type(1) {
        justify-content: center;

        ${mediaQuery('md')} {
            width: fit-content;
            justify-content: flex-start;
            height: fit-content;
            align-items: center;
        }
    }
`

const CountryContactInfoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    transition: all 0.25s ease-in-out;

    &.hidden {
        opacity: 0;
    }

    ${mediaQuery('md')} {
        justify-content: center;
    }
`
const CountrySelector = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-shrink: 0;
    gap: 8px;
    transition: all 0.2s ease-in-out;

    h6 {
        margin: 0;
        text-transform: uppercase;
    }

    svg {
        margin: 0;
    }

    :not(.selected) {
        opacity: 0.5;

        :hover {
            cursor: pointer;
            opacity: 1;
        }
    }

    &.selected {
        h6 {
            font-weight: bold;
        }
    }
`
const CountrySelectorWrapper = styled.div`
    display: flex;
    gap: 24px;
    justify-content: flex-start;
    width: fit-content;

    hr {
        margin: auto 0;
        width: 2px;
        height: 24px;
        color: transparent;
        background-color: ${props => props.theme.colors.accent};
        border: none;
    }
`

const InternationalContactInformations = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    padding: 40px;
`

const ContactTileWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: calc(100vw - 64px);
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    margin: 40px 0 16px 0;
    padding: 112px 16px 32px 16px;
`
const CountryWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    left: 0;
    right: 0;
    margin: auto;
    top: -40px;

    h2 {
        margin: 0;
        line-height: 1em;
        text-transform: uppercase;
    }
`
const FlagWrapper = styled.div`
    display: flex;
`

const ModalNoForm: React.FC<ModalProps> = ({ show, setShow, modalType }) => {
    const [countryIndex, setCountryIndex] = useState(0)
    const [contentFadeClass, setContentFadeClass] = useState('')
    const [contactAdress, setContactAdress] = useState<LangKeys>('france.adress')
    const [contactCity, setContactCity] = useState<LangKeys>('france.city')
    const [contactMail, setContactMail] = useState<LangKeys>('france.mail')
    const [recrutmentMail, setRecrutmentMail] = useState<LangKeys>('france.recrutment')
    const [contactPhone, setContactPhone] = useState<LangKeys>('france.phone')
    const [mailExtension, setMailExtension] = useState('.fr')
    const [mailSubject, setMailSubject] = useState(modalType === 'intern' ? 'Candidature interne' : 'Candidature consultant')
    const [mailSubjectCountryTag, setMailSubjectCountryTag] = useState('FR')
    const [catchphrase, setCatchphrase] = useState<LangKeys>('contact.catchphrase')

    useEffect(() => {
        if (countryIndex === 0) {
            setContactAdress('france.adress')
            setContactCity('france.city')
            setContactMail('france.mail')
            setRecrutmentMail('france.recrutment')
            setContactPhone('france.phone')
            setMailExtension('.fr')
            setMailSubjectCountryTag('FR')
        } else if (countryIndex === 1) {
            setContactAdress('belgium.adress')
            setContactCity('belgium.city')
            setContactMail('belgium.mail')
            setRecrutmentMail('belgium.recrutment')
            setContactPhone('belgium.phone')
            setMailExtension('.be')
            setMailSubjectCountryTag('BE')
        } else if (countryIndex === 2) {
            setContactAdress('usa.adress')
            setContactCity('usa.city')
            setContactMail('usa.mail')
            setRecrutmentMail('usa.recrutment')
            setContactPhone('usa.phone')
            setMailExtension('.us')
            setMailSubjectCountryTag('US')
        } else if (countryIndex === 3) {
            setContactAdress('luxembourg.adress')
            setContactCity('luxembourg.city')
            setContactMail('luxembourg.mail')
            setRecrutmentMail('luxembourg.recrutment')
            setContactPhone('luxembourg.phone')
            setMailExtension('.lu')
            setMailSubjectCountryTag('LU')
        } else {
            setContactAdress('switzerland.adress')
            setContactCity('switzerland.city')
            setContactMail('switzerland.mail')
            setRecrutmentMail('switzerland.recrutment')
            setContactPhone('switzerland.phone')
            setMailExtension('.ch')
            setMailSubjectCountryTag('CH')
        }

        setTimeout(() => {
            setContentFadeClass('')
        }, 250)
    }, [countryIndex])

    useEffect(() => {
        if (modalType === 'classic') {
            setCatchphrase('contact.catchphrase')
        } else if (modalType === 'intern') {
            setCatchphrase('contact.intern')
            setMailSubject('Candidature interne')
        } else if (modalType === 'consultant') {
            setCatchphrase('contact.consultant')
            setMailSubject('Candidature consultant')
        }
    }, [show])

    const ContactTilesCollection = [
        <ContactTileWrapper key={0}>
            <CountryWrapper>
                <FlagWrapper>
                    <FranceFlag style={{ width: '120px', height: '80px' }} viewBox="0 0 900 600" />
                </FlagWrapper>
                <h2>
                    <Text value="country.france" />
                </h2>
            </CountryWrapper>
            <CountryContactInfoWrapper>
                <ContactInfoWrapper>
                    <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                    <span style={{ paddingTop: '1px' }}>
                        <Text value="france.adress" />
                        ,&nbsp;
                        <span style={{ fontWeight: 'bold' }}>
                            <br />
                            <Text value="france.city" />
                        </span>
                    </span>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a
                        href={
                            modalType === 'classic'
                                ? `mailto:contact_fr@amapii.com`
                                : `mailto:recrutement_fr@amapii.com?subject=[${mailSubject} France]`
                        }
                    >
                        <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value={modalType === 'classic' ? 'france.mail' : 'france.recrutment'} />
                        </span>
                    </a>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a href="tel:0973892533">
                        <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value="france.phone" />
                        </span>
                    </a>
                </ContactInfoWrapper>
            </CountryContactInfoWrapper>
        </ContactTileWrapper>,
        <ContactTileWrapper key={1}>
            <CountryWrapper>
                <FlagWrapper>
                    <BelgiumFlag style={{ width: '120px', height: '80px' }} viewBox="0 0 900 600" />
                </FlagWrapper>
                <h2>
                    <Text value="country.belgium" />
                </h2>
            </CountryWrapper>
            <CountryContactInfoWrapper>
                <ContactInfoWrapper>
                    <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                    <span style={{ paddingTop: '1px' }}>
                        <Text value="belgium.adress" />
                        ,&nbsp;
                        <span style={{ fontWeight: 'bold' }}>
                            <br />
                            <Text value="belgium.city" />
                        </span>
                    </span>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a
                        href={
                            modalType === 'classic'
                                ? `mailto:contact_be@amapii.com`
                                : `mailto:recrutement_be@amapii.com?subject=[${mailSubject} Belgique]`
                        }
                    >
                        <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value={modalType === 'classic' ? 'belgium.mail' : 'belgium.recrutment'} />
                        </span>
                    </a>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a href="tel:0973892533">
                        <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value="belgium.phone" />
                        </span>
                    </a>
                </ContactInfoWrapper>
            </CountryContactInfoWrapper>
        </ContactTileWrapper>,
        <ContactTileWrapper key={2}>
            <CountryWrapper>
                <FlagWrapper>
                    <USAFlag style={{ width: '120px', height: '80px' }} />
                </FlagWrapper>
                <h2>
                    <Text value="country.usa" />
                </h2>
            </CountryWrapper>
            <CountryContactInfoWrapper>
                <ContactInfoWrapper>
                    <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                    <span style={{ paddingTop: '1px' }}>
                        <Text value="usa.adress" />
                        ,&nbsp;
                        <span style={{ fontWeight: 'bold' }}>
                            <br />
                            <Text value="usa.city" />
                        </span>
                    </span>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a
                        href={
                            modalType === 'classic'
                                ? `mailto:contact_us@amapii.com`
                                : `mailto:recrutement_us@amapii.com?subject=[${mailSubject} USA]`
                        }
                    >
                        <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value={modalType === 'classic' ? 'usa.mail' : 'usa.recrutment'} />
                        </span>
                    </a>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a href="tel:0973892533">
                        <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value="usa.phone" />
                        </span>
                    </a>
                </ContactInfoWrapper>
            </CountryContactInfoWrapper>
        </ContactTileWrapper>,
        <ContactTileWrapper key={3}>
            <CountryWrapper>
                <FlagWrapper>
                    <LuxembourgFlag style={{ width: '120px', height: '80px' }} viewBox="0 0 900 600" />
                </FlagWrapper>
                <h2>
                    <Text value="country.luxembourg" />
                </h2>
            </CountryWrapper>
            <CountryContactInfoWrapper>
                <ContactInfoWrapper>
                    <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                    <span style={{ paddingTop: '1px' }}>
                        <Text value="luxembourg.adress" />
                        ,&nbsp;
                        <span style={{ fontWeight: 'bold' }}>
                            <br />
                            <Text value="luxembourg.city" />
                        </span>
                    </span>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a
                        href={
                            modalType === 'classic'
                                ? `mailto:contact_lu@amapii.com`
                                : `mailto:recrutement_lu@amapii.com?subject=[${mailSubject} Luxembourg]`
                        }
                    >
                        <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value={modalType === 'classic' ? 'luxembourg.mail' : 'luxembourg.recrutment'} />
                        </span>
                    </a>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a href="tel:0973892533">
                        <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value="luxembourg.phone" />
                        </span>
                    </a>
                </ContactInfoWrapper>
            </CountryContactInfoWrapper>
        </ContactTileWrapper>,
        <ContactTileWrapper key={4}>
            <CountryWrapper>
                <FlagWrapper>
                    <SwitzerlandFlag style={{ width: '120px', height: '80px' }} />
                </FlagWrapper>
                <h2>
                    <Text value="country.switzerland" />
                </h2>
            </CountryWrapper>
            <CountryContactInfoWrapper>
                <ContactInfoWrapper>
                    <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                    <span style={{ paddingTop: '1px' }}>
                        <Text value="switzerland.adress" />
                        ,&nbsp;
                        <span style={{ fontWeight: 'bold' }}>
                            <br />
                            <Text value="switzerland.city" />
                        </span>
                    </span>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a
                        href={
                            modalType === 'classic'
                                ? `mailto:contact_ch@amapii.com`
                                : `mailto:recrutement_ch@amapii.com?subject=[${mailSubject} Suisse]`
                        }
                    >
                        <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value={modalType === 'classic' ? 'switzerland.mail' : 'switzerland.recrutment'} />
                        </span>
                    </a>
                </ContactInfoWrapper>
                <ContactInfoWrapper>
                    <a href="tel:0973892533">
                        <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                        <span>
                            <Text value="switzerland.phone" />
                        </span>
                    </a>
                </ContactInfoWrapper>
            </CountryContactInfoWrapper>
        </ContactTileWrapper>
    ]

    return show ? (
        <ModalWrapper>
            <ContentWrapper>
                <CTA
                    weight="secondary"
                    type="icon"
                    color="white"
                    icon="close"
                    iconSize={24}
                    className="close-button"
                    padding="6px"
                    onClick={() => {
                        setShow(false)
                        document.body.classList.remove('prevent_scrolling')
                    }}
                    rounded
                />
                <ModalContent>
                    <ModalHeader>
                        <SectionTitle sectionSubTitle="contactUs" sectionTitle={catchphrase} titleAlignement="left" />
                    </ModalHeader>
                    <InternationalContactInformations className="no-mobile-display">
                        <CountrySelectorWrapper>
                            <CountrySelector
                                onClick={() => {
                                    setContentFadeClass('hidden')
                                    setTimeout(() => {
                                        setCountryIndex(0)
                                    }, 200)
                                }}
                                className={countryIndex === 0 ? 'selected' : ''}
                            >
                                <FranceFlag style={{ width: '24px', height: '16px' }} viewBox="0 0 900 600" />
                                <h6>
                                    <Text value="country.france" />
                                </h6>
                            </CountrySelector>
                            <hr />
                            <CountrySelector
                                onClick={() => {
                                    setTimeout(() => {
                                        setCountryIndex(1)
                                    }, 200)
                                    setContentFadeClass('hidden')
                                }}
                                className={countryIndex === 1 ? 'selected' : ''}
                            >
                                <BelgiumFlag style={{ width: '24px', height: '16px' }} viewBox="0 0 900 600" />
                                <h6>
                                    <Text value="country.belgium" />
                                </h6>
                            </CountrySelector>
                            <hr />
                            <CountrySelector
                                onClick={() => {
                                    setTimeout(() => {
                                        setCountryIndex(2)
                                    }, 200)
                                    setContentFadeClass('hidden')
                                }}
                                className={countryIndex === 2 ? 'selected' : ''}
                            >
                                <USAFlag style={{ width: '24px', height: '16px' }} />
                                <h6>
                                    <Text value="country.usa" />
                                </h6>
                            </CountrySelector>
                            <hr />
                            <CountrySelector
                                onClick={() => {
                                    setTimeout(() => {
                                        setCountryIndex(3)
                                    }, 200)
                                    setContentFadeClass('hidden')
                                }}
                                className={countryIndex === 3 ? 'selected' : ''}
                            >
                                <LuxembourgFlag style={{ width: '24px', height: '16px' }} viewBox="0 0 900 600" />
                                <h6>
                                    <Text value="country.luxembourg" />
                                </h6>
                            </CountrySelector>
                            <hr />
                            <CountrySelector
                                onClick={() => {
                                    setTimeout(() => {
                                        setCountryIndex(4)
                                    }, 200)
                                    setContentFadeClass('hidden')
                                }}
                                className={countryIndex === 4 ? 'selected' : ''}
                            >
                                <SwitzerlandFlag style={{ width: '24px', height: '16px' }} />
                                <h6>
                                    <Text value="country.switzerland" />
                                </h6>
                            </CountrySelector>
                        </CountrySelectorWrapper>
                        <CountryContactInfoWrapper className={contentFadeClass}>
                            <ContactInfoWrapper>
                                <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                                <span style={{ display: 'flex', paddingTop: '1px', alignItems: 'baseline' }}>
                                    <Text value={contactAdress} />
                                    ,&nbsp;
                                    <span style={{ fontWeight: 'bold' }}>
                                        <Text value={contactCity} />
                                    </span>
                                </span>
                            </ContactInfoWrapper>
                            <ContactInfoWrapper>
                                <a
                                    href={
                                        modalType === 'classic'
                                            ? `mailto:contact${mailExtension}@amapii.com`
                                            : `mailto:recrutement${mailExtension}@amapii.com?subject=[${mailSubject} ${mailSubjectCountryTag}]`
                                    }
                                >
                                    <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                                    <span>
                                        <Text value={modalType === 'classic' ? contactMail : recrutmentMail} />
                                    </span>
                                </a>
                            </ContactInfoWrapper>
                            <ContactInfoWrapper>
                                <a href="tel:0973892533">
                                    <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                                    <span>
                                        <Text value={contactPhone} />
                                    </span>
                                </a>
                            </ContactInfoWrapper>
                        </CountryContactInfoWrapper>
                    </InternationalContactInformations>
                    <MobileSlider className="slider-wrapper no-desk-display" slidesList={ContactTilesCollection} margin={16} />
                </ModalContent>
            </ContentWrapper>
        </ModalWrapper>
    ) : (
        <></>
    )
}

export default ModalNoForm
