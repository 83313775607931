import React from 'react'
import Footer from '../components/layout/Footer'
import MainNavigation from '../components/layout/MainNavigation'
import SectionNavigation from '../components/layout/SectionNavigation'
import HeroSection from '../components/sections/HeroSection'
import OurServices from '../components/sections/OurServices'
import ReviewsAndPartners from '../components/sections/Reviews'
import Benefits from '../components/sections/Benefits'
import JoinUs from '../components/sections/JoinUs'
import LastNews from '../components/sections/LastNews'
import Growth from '../components/sections/Growth'

const HomePage: React.FC = () => (
    <>
        <MainNavigation />
        <HeroSection />
        <Growth />
        <SectionNavigation />
        <OurServices />
        <ReviewsAndPartners />
        <Benefits className="no-desk-display" format="mobile" />
        <JoinUs />
        <LastNews />
        <Footer />
    </>
)

export default HomePage
