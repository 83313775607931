import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface SliderProps {
    itemsList: ReactElement[]
    spacing: number
    direction?: string
    speed?: number
    itemSize?: number[] | string[]
    paused?: boolean
}

const SliderContainer = styled.div`
    display: flex;
    overflow-x: clip;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
`

const SlidingDiv = styled.div`
    display: flex;
    width: auto;
    flex-shrink: 0;

    &.left {
        animation-name: scrollLeft;
        animation: scrollLeft linear infinite;

        @keyframes scrollLeft {
            0% {
                transform: translateX(0%);
            }
            100% {
                transform: translateX(-100%);
            }
        }
    }
    &.right {
        animation-name: scrollRight;
        animation: scrollRight linear infinite;

        @keyframes scrollRight {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0%);
            }
        }
    }
    &.paused {
        animation-play-state: paused;
    }
`

const SliderItem = styled.span`
    display: flex;
    width: auto;
    height: auto;
    flex-shrink: 0;

    img {
        object-fit: contain;
        width: auto;
        max-width: 200px;
        height: 100%;
    }
`

const InfiniteSlider: React.FC<SliderProps> = ({ itemsList, itemSize, spacing, direction, speed, paused }) => {
    const itemsListed = itemsList.map(item => (
        <SliderItem key={item.key} style={{ width: itemSize ? itemSize[0] : 'auto', height: itemSize ? itemSize[1] : 'auto' }}>
            {item}
        </SliderItem>
    ))
    const [relativeDuration, setRelativeDuration] = useState(30)

    const slidingDiv = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (slidingDiv.current !== null) {
            setRelativeDuration(slidingDiv.current?.offsetWidth * 0.01 * (1 / (speed ?? 1)))
        }
    }, [itemsList])

    return (
        <SliderContainer>
            <div style={{ display: 'flex', width: '100%' }}>
                <SlidingDiv
                    ref={slidingDiv}
                    className={`${direction === 'left' ? 'left' : 'right'} ${paused ? 'paused' : ''}`}
                    style={{ gap: spacing, paddingLeft: spacing, animationDuration: `${relativeDuration}s` }}
                >
                    {itemsListed}
                </SlidingDiv>
                <SlidingDiv
                    className={`${direction === 'left' ? 'left' : 'right'} ${paused ? 'paused' : ''}`}
                    style={{ gap: spacing, paddingLeft: spacing, animationDuration: `${relativeDuration}s` }}
                >
                    {itemsListed}
                </SlidingDiv>
                <SlidingDiv
                    className={`${direction === 'left' ? 'left' : 'right'} ${paused ? 'paused' : ''}`}
                    style={{ gap: spacing, paddingLeft: spacing, animationDuration: `${relativeDuration}s` }}
                >
                    {itemsListed}
                </SlidingDiv>
            </div>
        </SliderContainer>
    )
}

export default InfiniteSlider
