import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import brandColors from '../../styles/colors'
import { ReactComponent as Account } from '../../assets/icons/account.svg'
import { ReactComponent as Email } from '../../assets/icons/email.svg'
import { ReactComponent as Localisation } from '../../assets/icons/localisation.svg'
import { ReactComponent as Phone } from '../../assets/icons/phone.svg'
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron_left.svg'
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron_right.svg'
import { ReactComponent as Performance } from '../../assets/icons/perf.svg'
import { ReactComponent as Wellbeing } from '../../assets/icons/wellbeing.svg'
import { ReactComponent as Support } from '../../assets/icons/support.svg'
import { ReactComponent as Close } from '../../assets/icons/close.svg'
import { ReactComponent as Dropdown } from '../../assets/icons/dropdown.svg'
import { ReactComponent as Message } from '../../assets/icons/message.svg'
import { ReactComponent as Submit } from '../../assets/icons/submit.svg'
import { ReactComponent as FranceFlag } from '../../assets/icons/FranceFlag.svg'
import { ReactComponent as UKFlag } from '../../assets/icons/UKFlag.svg'
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg'
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg'
import { ReactComponent as LinkedIn } from '../../assets/icons/linkedin.svg'

interface IconProps {
    icon?: string
    size?: number
    iconColor?: string
    onClick?: () => void
}

const IconFrame = styled.span`
    display: flex;
`

const DynamicIcon: React.FC<IconProps> = ({ icon, size, iconColor, onClick }) => {
    const [fillColor, setFillColor] = useState('inherit')
    const iconsMap = [
        { iconName: 'account', component: Account },
        { iconName: 'email', component: Email },
        { iconName: 'pin', component: Localisation },
        { iconName: 'phone', component: Phone },
        { iconName: 'facebook', component: Facebook },
        { iconName: 'instagram', component: Instagram },
        { iconName: 'linkedin', component: LinkedIn },
        { iconName: 'chevronLeft', component: ChevronLeft },
        { iconName: 'chevronRight', component: ChevronRight },
        { iconName: 'performance', component: Performance },
        { iconName: 'wellbeing', component: Wellbeing },
        { iconName: 'support', component: Support },
        { iconName: 'close', component: Close },
        { iconName: 'dropdown', component: Dropdown },
        { iconName: 'message', component: Message },
        { iconName: 'submit', component: Submit },
        { iconName: 'france-flag', component: FranceFlag },
        { iconName: 'UK-flag', component: UKFlag }
    ]

    useEffect(() => {
        if (iconColor === 'main') {
            setFillColor(brandColors.main)
        } else if (iconColor === 'accent') {
            setFillColor(brandColors.accent)
        } else if (iconColor === 'white') {
            setFillColor(brandColors.white)
        } else if (iconColor === 'black') {
            setFillColor(brandColors.black)
        } else if (iconColor !== undefined) {
            setFillColor(iconColor)
        }
    }, [])
    const RenderedIcon = iconsMap.find(({ iconName }) => iconName === icon)?.component

    return (
        <IconFrame onClick={onClick} style={{ width: size, height: size, fill: fillColor }}>
            {RenderedIcon ? <RenderedIcon style={{ width: `inherit`, height: `inherit`, fill: `inherit` }} /> : <></>}
        </IconFrame>
    )
}

export default DynamicIcon
