export function setItem(key: string, value: unknown): void {
    const data = typeof value === 'object' ? JSON.stringify(value) : String(value)
    window.localStorage.setItem(key, data)
}

export function getItem(key: string): string | null {
    return window.localStorage.getItem(key)
}

export function removeItem(key: string): void {
    window.localStorage.removeItem(key)
}
