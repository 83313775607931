import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import InfiniteSlider from '../common/InfiniteSlider'
import Text from '../common/Text'
import mediaQuery from '../../styles/media'
import { ReactComponent as GrowthMap } from '../../assets/images/growth_map.svg'
import { ReactComponent as GrowthArrow } from '../../assets/icons/decorations/growth_arrow.svg'
import yearlyData from '../../assets/locales/yearlyData.json'
import BPCE from '../../assets/images/clients/BPCE.png'
import Covage from '../../assets/images/clients/Covage.png'
import CSGroup from '../../assets/images/clients/CS_Group.png'
import Dior from '../../assets/images/clients/Dior.svg'
import Excellium from '../../assets/images/clients/Excellium.svg'
import Ista from '../../assets/images/clients/Ista.png'
import ITNI from '../../assets/images/clients/ITNI.svg'
import KaufmanAndBroad from '../../assets/images/clients/Kaufman_and_Broad.svg'
import LOreal from '../../assets/images/clients/L_Oreal.svg'
import MNT from '../../assets/images/clients/MNT.svg'
import Odigo from '../../assets/images/clients/Odigo.svg'
import RATPDev from '../../assets/images/clients/RATP_Dev.svg'
import RATPSys from '../../assets/images/clients/RATP_Smart_Systems.png'
import Rexel from '../../assets/images/clients/Rexel.png'
import Safran from '../../assets/images/clients/Safran.png'
import Saur from '../../assets/images/clients/Saur.svg'
import SNOPGroup from '../../assets/images/clients/SNOP_Groupe_FSD.png'
import Suntory from '../../assets/images/clients/Suntory.svg'
import Toyota from '../../assets/images/clients/Toyota.svg'
import useDisplayCheck from '../../hooks/useDisplayCheck'
import SectionTitle from '../common/SectionTitle'

type Year = 2020 | 2021 | 2022 | 2023

const GrowthWrapper = styled.div`
    display: flex;
    width: 100%;
    background: ${props => props.theme.colors.white50percent};
    box-shadow: ${props => props.theme.shadow.firstLevel};
    backdrop-filter: ${props => props.theme.blur.classic};
    position: relative;
    padding: 72px 4% 120px 4%;
    justify-content: center;
    gap: 128px;
    z-index: 1;
    scroll-margin-top: 200px;
    h2 {
        color: ${props => props.theme.colors.main};
    }

    ${mediaQuery('md')} {
        flex-direction: column;
        padding: 48px 16px 44px 16px;
        gap: 16px;
    }
`

const MapWrapper = styled.div`
    display: flex;
    position: relative;
    margin-top: -112px;
    justify-content: center;
    min-width: 432px;
    min-height: 432px;

    svg {
        .marker,
        .arrow_head,
        .arrow_stroke,
        .flag,
        .country {
            transition: all 0.3s ease-in-out;
        }

        &.year_2021,
        &.year_2022,
        &.year_2023 {
            #BE_arrow_stroke,
            #BE {
                stroke-dashoffset: 0;
                transition-delay: 0;
                stroke-opacity: 1;
                fill-opacity: 1;
            }
            #BE_arrow_head {
                stroke-dashoffset: 0;
                transition-delay: 0.3s;
            }
            #BE_marker,
            #BE_flag {
                opacity: 1;
                transition-delay: 0.6s;
            }
        }
        &.year_2022,
        &.year_2023 {
            #USA_arrow_stroke {
                stroke-dashoffset: 0;
                transition-delay: 0;
            }
            #USA_arrow_head {
                stroke-dashoffset: 0;
                transition-delay: 0.3s;
            }
            #USA_marker,
            #USA_flag {
                opacity: 1;
                transition-delay: 0;
            }
            #LU_arrow_head,
            #CH_arrow_head {
                transition-delay: 0.2s;
            }
            #LU_arrow_stroke,
            #CH_arrow_stroke {
                transition-delay: 0.4s;
            }
        }
        &.year_2023 {
            #CH_arrow_stroke,
            #LU_arrow_stroke,
            #CH,
            #LU {
                stroke-dashoffset: 0;
                transition-delay: 0s !important;
                stroke-opacity: 1;
                fill-opacity: 1;
            }
            #CH_arrow_head,
            #LU_arrow_head {
                stroke-dashoffset: 0;
                transition-delay: 0.3s !important;
            }
            #CH_marker,
            #CH_flag,
            #LU_marker,
            #LU_flag {
                opacity: 1;
                transition-delay: 0.6s;
            }
        }
    }

    ${mediaQuery('md')} {
        min-width: initial;
        min-height: initial;
        width: fit-content;
        order: 2;
        left: 0;
        right: 0;
        bottom: 236px;
        bottom: -28px;
        margin: 0 auto;
        height: fit-content;
        position: absolute;
    }
`
const YearSelectorWrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: -28px;
    padding: 8px;
    gap: 8px;
    border-radius: 8px;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadow.secondLevel};

    ${mediaQuery('md')} {
        position: relative;
        bottom: initial;
    }
`

const YearSelectorButton = styled.span`
    display: flex;
    height: 40px;
    width: 80px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: all 0.3s ease-in-out;

    :hover {
        cursor: pointer;
    }

    &.current {
        color: ${props => props.theme.colors.white};
        font-weight: 700;
        pointer-events: none;
        cursor: default;
    }

    ${mediaQuery('md')} {
        width: 64px;
    }

    ${mediaQuery('sm')} {
        width: 56px;
        height: 32px;
    }
`

const CurrentYearMarker = styled.span`
    display: block;
    position: absolute;
    width: 80px;
    height: 40px;
    border-radius: 8px;
    background: ${props => props.theme.colors.main};
    transition: all 0.3s ease-in-out;
    &.year_2020 {
        left: 8px;
    }
    &.year_2021 {
        left: 96px;
    }
    &.year_2022 {
        left: 184px;
    }
    &.year_2023 {
        left: 272px;
    }

    ${mediaQuery('md')} {
        width: 64px;

        &.year_2021 {
            left: 80px;
        }
        &.year_2022 {
            left: 152px;
        }
        &.year_2023 {
            left: 224px;
        }
    }

    ${mediaQuery('sm')} {
        width: 56px;
        height: 32px;

        &.year_2021 {
            left: 72px;
        }
        &.year_2022 {
            left: 136px;
        }
        &.year_2023 {
            left: 200px;
        }
    }
`

const DataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: fit-content;
    align-self: flex-end;

    ${mediaQuery('md')} {
        gap: 16px;
    }
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    h2 > svg {
        display: block;
        position: absolute;
        left: -40px;
        top: -32px;
    }
`

const YearlyDataWrapper = styled.div`
    display: flex;
    gap: 16px;

    ${mediaQuery('md')} {
        flex-wrap: wrap;
    }
`

const DataTile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadow.secondLevel};
    border-radius: 8px;
    flex-grow: 1;
    max-width: 200px;

    :last-child {
        flex-grow: 0;
        max-width: initial;
    }

    span {
        line-height: 1em;
        &.data-value {
            display: flex;
            font-size: 6rem;
            font-weight: 700;
            color: ${props => props.theme.colors.accent};
        }

        &.data-label {
            font-size: 3rem;
            font-weight: 700;
            color: ${props => props.theme.colors.main};
            text-transform: uppercase;
        }
    }

    ${mediaQuery('md')} {
        width: calc(50% - 16px);
        flex-grow: 1;
        max-width: initial;

        :last-child {
            flex-grow: 1;
            max-width: initial;
        }

        span {
            line-height: 1em;
            &.data-value {
                display: flex;
                font-size: 3rem;
                font-weight: 700;
                color: ${props => props.theme.colors.accent};
            }

            &.data-label {
                font-size: 1.5rem;
                font-weight: 700;
                color: ${props => props.theme.colors.main};
                text-transform: uppercase;
            }
        }
    }
`

const Client = styled.div`
    display: flex;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadow.firstLevel};
    border-radius: 8px;
    padding: 16px 24px;

    img {
        height: 40px;
        width: auto;
        object-fit: contain;
    }
`

const ClientSliderWrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: -24px;
    left: 0;
    width: 100%;

    ${mediaQuery('md')} {
        bottom: -36px;
        display: none;
    }
`

const ClientList = [
    <Client key="client-logo-1">
        <img src={BPCE} alt="Logo de la BPCE" />
    </Client>,
    <Client key="client-logo-2">
        <img src={Covage} alt="Logo de Covage" />
    </Client>,
    <Client key="client-logo-3">
        <img src={CSGroup} alt="Logo de CS Group" />
    </Client>,
    <Client key="client-logo-4">
        <img src={Dior} alt="Logo de Dior" />
    </Client>,
    <Client key="client-logo-5">
        <img src={Excellium} alt="Logo d'Excellium" />
    </Client>,
    <Client key="client-logo-6">
        <img src={Ista} alt="Logo de Ista" />
    </Client>,
    <Client key="client-logo-7">
        <img src={ITNI} alt="Logo de ITNI" />
    </Client>,
    <Client key="client-logo-8">
        <img src={KaufmanAndBroad} alt="Logo de Kaufmand & Broad" />
    </Client>,
    <Client key="client-logo-9">
        <img src={LOreal} alt="Logo de L'Oréal" />
    </Client>,
    <Client key="client-logo-10">
        <img src={MNT} alt="Logo de MNT" />
    </Client>,
    <Client key="client-logo-11">
        <img src={Odigo} alt="Logo d'Odigo" />
    </Client>,
    <Client key="client-logo-12">
        <img src={RATPDev} alt="Logo de RATP Dev" />
    </Client>,
    <Client key="client-logo-13">
        <img src={RATPSys} alt="Logo de RATP Smart Systems" />
    </Client>,
    <Client key="client-logo-14">
        <img src={Rexel} alt="Logo de Rexel" />
    </Client>,
    <Client key="client-logo-15">
        <img src={Safran} alt="Logo de Safran" />
    </Client>,
    <Client key="client-logo-16">
        <img src={Saur} alt="Logo de Saur" />
    </Client>,
    <Client key="client-logo-17">
        <img src={SNOPGroup} alt="Logo de SNOP - Groupe FSD" />
    </Client>,
    <Client key="client-logo-18">
        <img src={Suntory} alt="Logo de Suntory" />
    </Client>,
    <Client key="client-logo-19">
        <img src={Toyota} alt="Logo de Toyota" />
    </Client>
]

const Growth: React.FC = () => {
    const [currentYear, setCurrentYear] = useState<Year>(2023)
    const [data, setData] = useState([
        yearlyData[`project.${currentYear}`],
        yearlyData[`partners.${currentYear}`],
        yearlyData[`parity.${currentYear}`],
        yearlyData[`countries.${currentYear}`]
    ])

    useEffect(() => {
        setData([
            yearlyData[`project.${currentYear}`],
            yearlyData[`partners.${currentYear}`],
            yearlyData[`parity.${currentYear}`],
            yearlyData[`countries.${currentYear}`]
        ])
    }, [currentYear])

    const observedSection = useRef<HTMLDivElement>(null)
    const [startAnimation, setStartAnimation] = useState(false)
    const [preAnimation, setPreAnimation] = useState(false)
    const [drawing, setDrawing] = useState(false)
    const [movingSlider, setMovingSlider] = useState(false)

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.9
    }

    const isVisible = useDisplayCheck(observedSection, options)

    useEffect(() => {
        if (isVisible === true && startAnimation === false) {
            setStartAnimation(true)
            setTimeout(() => {
                setDrawing(true)
            }, 2400)
            setTimeout(() => {
                setMovingSlider(true)
            }, 2300)
        }
        setPreAnimation(true)
    }, [isVisible])

    return (
        <GrowthWrapper ref={observedSection} id="aboutUs">
            <MapWrapper className={`${preAnimation ? 'animated display' : 'animated hidden'} ${startAnimation ? 'delay-0' : 'delay-8'}`}>
                <GrowthMap className={`year_${currentYear} no-mobile-display`} />
                <YearSelectorWrapper>
                    <CurrentYearMarker className={`year_${currentYear}`} />
                    <YearSelectorButton onClick={() => setCurrentYear(2020)} className={currentYear === 2020 ? 'current' : undefined}>
                        2020
                    </YearSelectorButton>
                    <YearSelectorButton onClick={() => setCurrentYear(2021)} className={currentYear === 2021 ? 'current' : undefined}>
                        2021
                    </YearSelectorButton>
                    <YearSelectorButton onClick={() => setCurrentYear(2022)} className={currentYear === 2022 ? 'current' : undefined}>
                        2022
                    </YearSelectorButton>
                    <YearSelectorButton onClick={() => setCurrentYear(2023)} className={currentYear === 2023 ? 'current' : undefined}>
                        2023
                    </YearSelectorButton>
                </YearSelectorWrapper>
            </MapWrapper>
            <DataWrapper>
                <TextWrapper className={`${startAnimation ? 'animated display' : 'animated hidden'} delay-4`}>
                    <SectionTitle sectionTitle="aboutUs.title" sectionSubTitle="aboutUs.subtitle" titleAlignement="left" />
                    <p>
                        <Text value="aboutUs.description" />
                    </p>
                </TextWrapper>
                <YearlyDataWrapper>
                    <DataTile className={`${startAnimation ? 'animated display' : 'animated hidden'} delay-8`}>
                        <span className="data-value">
                            {/* <AnimatedNumber animateToNumber={data[0]} configs={[{ friction: 50, progress: 300 }]} /> */}
                            {data[0]}
                        </span>
                        <span className="data-label">
                            <Text value="aboutUs.dataKey.1" />
                        </span>
                    </DataTile>
                    <DataTile className={`${startAnimation ? 'animated display' : 'animated hidden'} delay-11`}>
                        <span className="data-value">
                            {/* <AnimatedNumber animateToNumber={data[3]} configs={[{ friction: 50, progress: 300 }]} /> */}
                            {data[3]}
                        </span>
                        <span className="data-label">
                            <Text value="aboutUs.dataKey.2" />
                        </span>
                    </DataTile>
                    <DataTile className={`${startAnimation ? 'animated display' : 'animated hidden'} delay-14`}>
                        <span className="data-value">
                            {/* <AnimatedNumber animateToNumber={data[2]} configs={[{ friction: 50, progress: 300 }]} />% */}
                            {data[2]}%
                        </span>
                        <span className="data-label">
                            <Text value="aboutUs.dataKey.3" />
                        </span>
                    </DataTile>
                    <DataTile className={`${startAnimation ? 'animated display' : 'animated hidden'} delay-17`}>
                        <span className="data-value">
                            {/* <AnimatedNumber animateToNumber={data[1]} configs={[{ friction: 50, progress: 300 }]} /> */}
                            {data[1]}
                        </span>
                        <span className="data-label">
                            <Text value="aboutUs.dataKey.4" />
                        </span>
                    </DataTile>
                </YearlyDataWrapper>
            </DataWrapper>
            <ClientSliderWrapper className={`${startAnimation ? 'animated display' : 'animated hidden'} delay-20`}>
                <InfiniteSlider itemsList={ClientList} spacing={16} direction="left" paused={!movingSlider} />
            </ClientSliderWrapper>
        </GrowthWrapper>
    )
}

export default Growth
