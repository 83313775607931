import React, { useEffect } from 'react'
import { defaultLanguage, LangKeys, LangOption, languageOptions, LanguageType, dictionaryList } from './types'
import { TranslationContextProps, TranslationContext } from './TranslationContext'
import utils from '../utils'

export const TranslationProvider: React.FC = ({ children }) => {
    const [language, setLanguage] = React.useState<LangOption>(defaultLanguage)
    const [dictionary, setDictionary] = React.useState<LanguageType>(dictionaryList[language])

    useEffect(() => {
        const localLanguage = utils.storage.getItem('I18N_LANGUAGE')
        if (localLanguage && language !== localLanguage) {
            const newLocalLanguage = localLanguage as LangOption
            setLanguage(newLocalLanguage)
            setDictionary(dictionaryList[newLocalLanguage])
        }
    }, [language])

    const provider = React.useMemo<TranslationContextProps>(() => {
        return {
            language,
            dictionary,
            t: (tid: LangKeys, value?: string): string => {
                return provider.dictionary[tid] || value || tid
            },
            changeLanguage: async (selected: LangOption) => {
                const newLanguage = languageOptions[selected] ? selected : defaultLanguage
                setLanguage(newLanguage)
                setDictionary(dictionaryList[newLanguage])
                utils.storage.setItem('I18N_LANGUAGE', newLanguage)
            }
        }
    }, [language])

    return <TranslationContext.Provider value={provider}>{children}</TranslationContext.Provider>
}
