import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import mediaQuery from '../../styles/media'
import { ReactComponent as Logo } from '../../assets/icons/amapii.svg'
import Text from '../common/Text'
import DynamicIcon from '../common/DynamicIcon'
import { ReactComponent as USAFlag } from '../../assets/icons/USAFlag.svg'
import { ReactComponent as FranceFlag } from '../../assets/icons/FranceFlag.svg'
import { ReactComponent as BelgiumFlag } from '../../assets/icons/BelgianFlag.svg'
import { ReactComponent as LuxembourgFlag } from '../../assets/icons/LuxFlag.svg'
import { ReactComponent as SwitzerlandFlag } from '../../assets/icons/SwitzFlag.svg'
import { LangKeys } from '../../i18n'
import MobileSlider from '../common/MobileSlider'
import CTA from '../common/CTA'

const FooterWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 40px calc((100% - 1128px) / 2);
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadow.invertedClassic};
    justify-content: space-between;
    gap: 80px;
    ${mediaQuery('md')} {
        flex-direction: column;
        justify-content: center;
        padding-bottom: 16px;
        gap: 24px;
    }
    h3 {
        text-align: center;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    svg {
        margin: 0 auto;
    }

    .translated-text {
        line-height: 3rem;
        height: fit-content;
    }

    ${mediaQuery('md')} {
        flex-direction: row;
        padding: 0 16px 24px 16px;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
    }
`

const ContactInfoWrapper = styled.div`
    width: fit-content;
    display: flex;
    gap: 8px;
    height: 24px;

    a {
        display: inherit;
        gap: inherit;
        font-weight: normal;
        line-height: 2rem;
    }

    :nth-of-type(1) {
        width: 100%;
        justify-content: center;

        ${mediaQuery('md')} {
            width: fit-content;
            justify-content: flex-start;
            height: fit-content;
            align-items: center;
        }
    }
`

const SocialMediaWrapper = styled.div`
    display: flex;
    gap: 32px;
    margin: 0 auto auto auto;

    ${mediaQuery('md')} {
        margin: 0;
    }
`

const CountryContactInfoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    transition: all 0.25s ease-in-out;

    &.hidden {
        opacity: 0;
    }

    ${mediaQuery('md')} {
        justify-content: center;
    }
`
const CountrySelector = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-shrink: 0;
    gap: 8px;
    transition: all 0.2s ease-in-out;

    h6 {
        margin: 0;
        text-transform: uppercase;
    }

    svg {
        margin: 0;
    }

    :not(.selected) {
        opacity: 0.5;

        :hover {
            cursor: pointer;
            opacity: 1;
        }
    }

    &.selected {
        h6 {
            font-weight: bold;
        }
    }
`
const CountrySelectorWrapper = styled.div`
    display: flex;
    gap: 24px;
    justify-content: flex-start;
    width: fit-content;

    hr {
        margin: auto 0;
        width: 2px;
        height: 24px;
        color: transparent;
        background-color: ${props => props.theme.colors.accent};
        border: none;
    }
`

const InternationalContactInformations = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
`

const ContactTileWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: calc(100vw - 32px);
    background-color: ${props => props.theme.colors.white};
    margin: 40px 0 0 0;
    padding: 112px 16px 32px 16px;
    border-radius: 8px;
`
const CountryWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    left: 0;
    right: 0;
    margin: auto;
    top: -40px;

    h2 {
        margin: 0;
        line-height: 1em;
        text-transform: uppercase;
    }
`
const FlagWrapper = styled.div`
    display: flex;
`

const ContactTilesCollection = [
    <ContactTileWrapper key={0}>
        <CountryWrapper>
            <FlagWrapper>
                <FranceFlag style={{ width: '120px', height: '80px' }} viewBox="0 0 900 600" />
            </FlagWrapper>
            <h2>
                <Text value="country.france" />
            </h2>
        </CountryWrapper>
        <CountryContactInfoWrapper>
            <ContactInfoWrapper>
                <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                <span style={{ paddingTop: '1px' }}>
                    <Text value="france.adress" />
                    ,&nbsp;
                    <span style={{ fontWeight: 'bold' }}>
                        <br />
                        <Text value="france.city" />
                    </span>
                </span>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="mailto:contact_fr@amapii.com">
                    <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="france.mail" />
                    </span>
                </a>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="tel:0973892533">
                    <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="france.phone" />
                    </span>
                </a>
            </ContactInfoWrapper>
        </CountryContactInfoWrapper>
    </ContactTileWrapper>,
    <ContactTileWrapper key={1}>
        <CountryWrapper>
            <FlagWrapper>
                <BelgiumFlag style={{ width: '120px', height: '80px' }} viewBox="0 0 900 600" />
            </FlagWrapper>
            <h2>
                <Text value="country.belgium" />
            </h2>
        </CountryWrapper>
        <CountryContactInfoWrapper>
            <ContactInfoWrapper>
                <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                <span style={{ paddingTop: '1px' }}>
                    <Text value="belgium.adress" />
                    ,&nbsp;
                    <span style={{ fontWeight: 'bold' }}>
                        <br />
                        <Text value="belgium.city" />
                    </span>
                </span>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="mailto:contact_be@amapii.com">
                    <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="belgium.mail" />
                    </span>
                </a>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="tel:0973892533">
                    <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="belgium.phone" />
                    </span>
                </a>
            </ContactInfoWrapper>
        </CountryContactInfoWrapper>
    </ContactTileWrapper>,
    <ContactTileWrapper key={2}>
        <CountryWrapper>
            <FlagWrapper>
                <USAFlag style={{ width: '120px', height: '80px' }} />
            </FlagWrapper>
            <h2>
                <Text value="country.usa" />
            </h2>
        </CountryWrapper>
        <CountryContactInfoWrapper>
            <ContactInfoWrapper>
                <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                <span style={{ paddingTop: '1px' }}>
                    <Text value="usa.adress" />
                    ,&nbsp;
                    <span style={{ fontWeight: 'bold' }}>
                        <br />
                        <Text value="usa.city" />
                    </span>
                </span>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="mailto:contact_us@amapii.com">
                    <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="usa.mail" />
                    </span>
                </a>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="tel:0973892533">
                    <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="usa.phone" />
                    </span>
                </a>
            </ContactInfoWrapper>
        </CountryContactInfoWrapper>
    </ContactTileWrapper>,
    <ContactTileWrapper key={3}>
        <CountryWrapper>
            <FlagWrapper>
                <LuxembourgFlag style={{ width: '120px', height: '80px' }} viewBox="0 0 900 600" />
            </FlagWrapper>
            <h2>
                <Text value="country.luxembourg" />
            </h2>
        </CountryWrapper>
        <CountryContactInfoWrapper>
            <ContactInfoWrapper>
                <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                <span style={{ paddingTop: '1px' }}>
                    <Text value="luxembourg.adress" />
                    ,&nbsp;
                    <span style={{ fontWeight: 'bold' }}>
                        <br />
                        <Text value="luxembourg.city" />
                    </span>
                </span>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="mailto:contact_lu@amapii.com">
                    <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="luxembourg.mail" />
                    </span>
                </a>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="tel:0973892533">
                    <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="luxembourg.phone" />
                    </span>
                </a>
            </ContactInfoWrapper>
        </CountryContactInfoWrapper>
    </ContactTileWrapper>,
    <ContactTileWrapper key={4}>
        <CountryWrapper>
            <FlagWrapper>
                <SwitzerlandFlag style={{ width: '120px', height: '80px' }} />
            </FlagWrapper>
            <h2>
                <Text value="country.switzerland" />
            </h2>
        </CountryWrapper>
        <CountryContactInfoWrapper>
            <ContactInfoWrapper>
                <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                <span style={{ paddingTop: '1px' }}>
                    <Text value="switzerland.adress" />
                    ,&nbsp;
                    <span style={{ fontWeight: 'bold' }}>
                        <br />
                        <Text value="switzerland.city" />
                    </span>
                </span>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="mailto:contact_ch@amapii.com">
                    <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="switzerland.mail" />
                    </span>
                </a>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <a href="tel:0973892533">
                    <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                    <span>
                        <Text value="switzerland.phone" />
                    </span>
                </a>
            </ContactInfoWrapper>
        </CountryContactInfoWrapper>
    </ContactTileWrapper>
]

const Footer: React.FC = () => {
    const [countryIndex, setCountryIndex] = useState(0)
    const [contentFadeClass, setContentFadeClass] = useState('')
    const [contactAdress, setContactAdress] = useState<LangKeys>('france.adress')
    const [contactCity, setContactCity] = useState<LangKeys>('france.city')
    const [contactMail, setContactMail] = useState<LangKeys>('france.mail')
    const [contactPhone, setContactPhone] = useState<LangKeys>('france.phone')
    const [mailExtension, setMailExtension] = useState('.fr')

    useEffect(() => {
        if (countryIndex === 0) {
            setContactAdress('france.adress')
            setContactCity('france.city')
            setContactMail('france.mail')
            setContactPhone('france.phone')
            setMailExtension('.fr')
        } else if (countryIndex === 1) {
            setContactAdress('belgium.adress')
            setContactCity('belgium.city')
            setContactMail('belgium.mail')
            setContactPhone('belgium.phone')
            setMailExtension('.be')
        } else if (countryIndex === 2) {
            setContactAdress('usa.adress')
            setContactCity('usa.city')
            setContactMail('usa.mail')
            setContactPhone('usa.phone')
            setMailExtension('.us')
        } else if (countryIndex === 3) {
            setContactAdress('luxembourg.adress')
            setContactCity('luxembourg.city')
            setContactMail('luxembourg.mail')
            setContactPhone('luxembourg.phone')
            setMailExtension('.lu')
        } else {
            setContactAdress('switzerland.adress')
            setContactCity('switzerland.city')
            setContactMail('switzerland.mail')
            setContactPhone('switzerland.phone')
            setMailExtension('.ch')
        }

        setTimeout(() => {
            setContentFadeClass('')
        }, 250)
    }, [countryIndex])

    return (
        <FooterWrapper>
            <ContentWrapper style={{ flexShrink: '0', justifyContent: 'space-between' }}>
                <ContentWrapper>
                    <Logo style={{ maxWidth: '296px' }} />
                    <span className="no-mobile-display" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                        <Text value="copyright" />
                    </span>
                </ContentWrapper>
                <ContentWrapper>
                    <h3 style={{ marginBottom: 0 }}>
                        <Text value="followUs" />
                    </h3>
                    <SocialMediaWrapper>
                        <CTA
                            icon="linkedin"
                            iconSize={32}
                            padding="8px"
                            weight="primary"
                            type="icon"
                            color="main"
                            onClick={() => {
                                window.open('https://www.linkedin.com/company/amapii, noreferrer, noopener')
                            }}
                            rounded
                        />
                        <CTA
                            icon="instagram"
                            iconSize={32}
                            padding="8px"
                            weight="primary"
                            type="icon"
                            color="main"
                            onClick={() => {
                                window.open('https://www.instagram.com/amap2i/?hl=fr, noreferrer, noopener')
                            }}
                            rounded
                        />
                        <CTA
                            icon="facebook"
                            iconSize={32}
                            weight="primary"
                            padding="8px"
                            type="icon"
                            color="main"
                            onClick={() => {
                                window.open('https://www.facebook.com/amap2i, noreferrer, noopener')
                            }}
                            rounded
                        />
                    </SocialMediaWrapper>
                </ContentWrapper>
            </ContentWrapper>
            <ContentWrapper className="no-mobile-display">
                <h3>
                    <Text value="contactUs" />
                </h3>
                <InternationalContactInformations>
                    <CountrySelectorWrapper>
                        <CountrySelector
                            onClick={() => {
                                setContentFadeClass('hidden')
                                setTimeout(() => {
                                    setCountryIndex(0)
                                }, 200)
                            }}
                            className={countryIndex === 0 ? 'selected' : ''}
                        >
                            <FranceFlag style={{ width: '24px', height: '16px' }} viewBox="0 0 900 600" />
                            <h6>
                                <Text value="country.france" />
                            </h6>
                        </CountrySelector>
                        <hr />
                        <CountrySelector
                            onClick={() => {
                                setTimeout(() => {
                                    setCountryIndex(1)
                                }, 200)
                                setContentFadeClass('hidden')
                            }}
                            className={countryIndex === 1 ? 'selected' : ''}
                        >
                            <BelgiumFlag style={{ width: '24px', height: '16px' }} viewBox="0 0 900 600" />
                            <h6>
                                <Text value="country.belgium" />
                            </h6>
                        </CountrySelector>
                        <hr />
                        <CountrySelector
                            onClick={() => {
                                setTimeout(() => {
                                    setCountryIndex(2)
                                }, 200)
                                setContentFadeClass('hidden')
                            }}
                            className={countryIndex === 2 ? 'selected' : ''}
                        >
                            <USAFlag style={{ width: '24px', height: '16px' }} />
                            <h6>
                                <Text value="country.usa" />
                            </h6>
                        </CountrySelector>
                        <hr />
                        <CountrySelector
                            onClick={() => {
                                setTimeout(() => {
                                    setCountryIndex(3)
                                }, 200)
                                setContentFadeClass('hidden')
                            }}
                            className={countryIndex === 3 ? 'selected' : ''}
                        >
                            <LuxembourgFlag style={{ width: '24px', height: '16px' }} viewBox="0 0 900 600" />
                            <h6>
                                <Text value="country.luxembourg" />
                            </h6>
                        </CountrySelector>
                        <hr />
                        <CountrySelector
                            onClick={() => {
                                setTimeout(() => {
                                    setCountryIndex(4)
                                }, 200)
                                setContentFadeClass('hidden')
                            }}
                            className={countryIndex === 4 ? 'selected' : ''}
                        >
                            <SwitzerlandFlag style={{ width: '24px', height: '16px' }} />
                            <h6>
                                <Text value="country.switzerland" />
                            </h6>
                        </CountrySelector>
                    </CountrySelectorWrapper>
                    <CountryContactInfoWrapper className={contentFadeClass}>
                        <ContactInfoWrapper>
                            <DynamicIcon icon="pin" size={24} iconColor="#c7a948" />
                            <span style={{ display: 'flex', paddingTop: '1px', alignItems: 'baseline' }}>
                                <Text value={contactAdress} />
                                ,&nbsp;
                                <span style={{ fontWeight: 'bold' }}>
                                    <Text value={contactCity} />
                                </span>
                            </span>
                        </ContactInfoWrapper>
                        <ContactInfoWrapper>
                            <a href={`mailto:contact${mailExtension}@amapii.com`}>
                                <DynamicIcon icon="email" size={24} iconColor="#c7a948" />
                                <span>
                                    <Text value={contactMail} />
                                </span>
                            </a>
                        </ContactInfoWrapper>
                        <ContactInfoWrapper>
                            <a href="tel:0973892533">
                                <DynamicIcon icon="phone" size={24} iconColor="#c7a948" />
                                <span>
                                    <Text value={contactPhone} />
                                </span>
                            </a>
                        </ContactInfoWrapper>
                    </CountryContactInfoWrapper>
                </InternationalContactInformations>
            </ContentWrapper>
            <span className="no-desk-display" style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <h3>
                    <Text value="contactUs" />
                </h3>
                <MobileSlider slidesList={ContactTilesCollection} />
            </span>
            <span className="no-desk-display" style={{ fontSize: '1.5rem', textAlign: 'center', marginTop: '40px' }}>
                <Text value="copyright" />
            </span>
        </FooterWrapper>
    )
}

export default Footer
