interface Breakpoint {
    xs: number
    sm: number
    md: number
    lg: number
    xl: number
    xxl: number
    mdHeight: number
}

const sizes: Breakpoint = {
    xs: 0,
    sm: 374,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 1921,
    mdHeight: 721
}

const mediaQuery = (breakpoint: keyof Breakpoint | number, type: 'min' | 'max' = 'max', direction: 'width' | 'height' = 'width'): string =>
    `@media only screen and (${type}-${direction}: ${typeof breakpoint === 'number' ? breakpoint : sizes[breakpoint]}px)`

export default mediaQuery
