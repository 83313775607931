import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SectionTitle from '../common/SectionTitle'
import Text from '../common/Text'
import Man1 from '../../assets/images/Man1.png'
import Man2 from '../../assets/images/Man2.png'
import Woman1 from '../../assets/images/Woman1.png'
import Woman2 from '../../assets/images/Woman2.png'
import Woman3 from '../../assets/images/Woman3.png'
import InfiniteSlider from '../common/OldInfiniteSlider'
import mediaQuery from '../../styles/media'
import Benefits from './Benefits'
import MobileSlider from '../common/MobileSlider'
import { LangKeys } from '../../i18n'

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1px;

    h2 {
        color: ${props => props.theme.colors.main};
    }
`

const HorizontalAnimationWrapper = styled.div`
    display: flex;
    height: 300vh;
    justify-content: flex-start;
    position: relative;
`

const FullScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 120px calc((100% - 1128px) / 2);
    position: sticky;
    top: 0;
    overflow: hidden;

    ${mediaQuery('mdHeight', 'max', 'height')} {
        padding: 120px calc((100% - 1128px) / 2);
    }
`

const ContentWrapper = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    color: ${props => props.theme.colors.main};
`

const SelectorAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: min-content;
    margin-top: 64px;

    ${mediaQuery('mdHeight', 'max', 'height')} {
        margin-top: 0;
    }
`

const ConsultantCategoryTrackingBarWrapper = styled.div`
    position: relative;
    display: flex;
    width: fit-content;
    margin-bottom: 12px;

    ::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: ${props => props.theme.colors.main20percent};
        bottom: -10px;
    }

    ${mediaQuery('mdHeight', 'max', 'height')} {
        margin: 32px 0;
    }
`

const ConsultantCategoryMobile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fonts.fontSizeIntermediate};
    font-weight: 600;
    height: 64px;
    box-sizing: border-box;
    padding: 8px 24px;

    ${mediaQuery('sm')} {
        height: 56px;
        font-size: ${props => props.theme.fonts.fontSizeNormal};
    }
`

const ConsultantCategory = styled.span`
    display: flex;
    justify-content: center;
    font-family: 'Noway';
    font-size: ${props => props.theme.fonts.fontSizeSmall};
    height: 20px;
    align-items: center;

    .translated-text {
        transition: all 0s ease-in-out;
    }

    &:not(.current) {
        .translated-text {
            margin-bottom: 4px;
        }
    }

    &.current {
        font-weight: bold;

        .translated-text {
            transition: all 0s 0.3s ease-in-out;
        }
    }

    &:not(.current):hover {
        cursor: pointer;
    }
`

const ConsultantCategoryCurrentTracker = styled.span`
    position: absolute;
    display: flex;
    height: 4px;
    background-color: ${props => props.theme.colors.accent};
    transition: all 0.3s ease-in-out;
    z-index: 2;
    bottom: -11px;
    border-radius: 4px;

    &.position-0 {
        width: 184px;
        left: 0;
    }
    &.position-1 {
        width: 128px;
        left: 184px;
    }
    &.position-2 {
        width: 72px;
        left: 312px;
    }
    &.position-3 {
        width: 152px;
        left: 384px;
    }
    &.position-4 {
        width: 120px;
        left: 536px;
    }
`

const CategoryDescription = styled.p`
    width: 100%;
    margin: 32px 0 0 0;
    text-align: justify;
    font-size: 18px;

    ${mediaQuery('mdHeight', 'max', 'height')} {
        margin: 8px 0 0 0;
        font-size: ${props => props.theme.fonts.fontSizeNormal};
    }

    ${mediaQuery('md')} {
        padding: 0 16px;
        margin: 24px 0;
        font-size: ${props => props.theme.fonts.fontSizeSmall};
        text-align: left;
    }

    ${mediaQuery('sm')} {
        margin: 16px 0;
        font-size: 14px;
    }
`

const PersonaesAnimationWrapper = styled.span`
    position: relative;
    width: 100%;
    height: 560px;

    ${mediaQuery('mdHeight', 'max', 'height')} {
        height: 408px;
    }
`

const PersonaesWrapper = styled.span`
    display: inline-flex;
    position: absolute;
    width: 100%;
    transition: all 1s 0.5s ease-in-out;
    gap: 100vw;

    &.position-0 {
        transform: translateX(0);
    }
    &.position-1 {
        transform: translateX(calc(-100% - 100vw));
    }
    &.position-2 {
        transform: translateX(calc(-200% - 200vw));
    }
    &.position-3 {
        transform: translateX(calc(-300% - 300vw));
    }
    &.position-4 {
        transform: translateX(calc(-400% - 400vw));
    }
`

const PersonaeContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    flex-shrink: 0;

    ${mediaQuery('mdHeight', 'max', 'height')} {
        justify-content: center;
    }
`

const BackgroundShape = styled.div`
    display: flex;
    position: relative;
    background-color: ${props => props.theme.colors.accent};
    box-shadow: ${props => props.theme.shadow.classic};
    width: 360px;
    height: 432px;
    margin-top: 128px;
    border-radius: 8px;

    ::before {
        content: '';
        display: block;
        position: absolute;
        width: 360px;
        height: 360px;
        bottom: -24px;
        left: -24px;
        border: 8px solid ${props => props.theme.colors.main};
        z-index: -1;
        border-radius: 12px;
    }

    ::after {
        content: '';
        display: block;
        position: absolute;
        top: 96px;
        width: 120px;
        height: 8px;
        background-color: ${props => props.theme.colors.main};
    }

    &.blue {
        background-color: ${props => props.theme.colors.main};

        ::before {
            border-color: ${props => props.theme.colors.accent};
        }

        ::after {
            background-color: ${props => props.theme.colors.accent};
        }
    }

    ${mediaQuery('mdHeight', 'max', 'height')} {
        width: 320px;
        height: 320px;
        margin-top: 88px;

        ::before {
            width: 320px;
            height: 320px;
        }

        ::after {
            width: 172px;
            top: 24px;
        }
    }
`

const PersonaePhoto = styled.img`
    height: 100%;
    object-fit: cover;
    position: absolute;
    transform: scaleX(-1);
    right: -144px;
    bottom: 0;
    z-index: 2;

    &.man1 {
        transform: scaleX(1);
        right: -100px;
    }

    &.woman2 {
        right: -25px;
    }

    &.man2 {
        right: -16px;
    }

    &.woman3 {
        transform: scaleX(1);
        object-fit: fill;
        right: 0;
    }

    ${mediaQuery('mdHeight', 'max', 'height')} {
        right: -64px;
        height: 120%;

        &.man1 {
            right: -24px;
        }

        &.man2,
        &.woman2 {
            right: 56px;
        }

        &.woman3 {
            right: 76px;
            height: 110%;
        }
    }
`

const JobsListWrapper = styled.span`
    display: flex;
    position: absolute;
    width: 720px;
    height: 168px;
    bottom: 32px;
    right: 360px;
    margin: 0;
    overflow: hidden;
    justify-content: flex-end;

    ${mediaQuery('md')} {
        padding: 0 16px;
        justify-content: space-between;
    }

    ${mediaQuery('mdHeight', 'max', 'height')} {
        width: 732px;
        right: 320px;
        height: 140px;
    }
`

const CategoryJobsList = styled.ul`
    display: flex;
    position: absolute;
    border-radius: 8px 0 0 8px;
    gap: 24px;
    flex-wrap: wrap;
    padding: 32px 40px 32px 32px;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.white50percent};
    backdrop-filter: blur(8px);
    box-shadow: ${props => props.theme.shadow.classic};
    margin: 0;
    transition: all 0.5s 1.5s ease-in-out;

    ${mediaQuery('mdHeight', 'max', 'height')} {
        width: 732px;
        row-gap: 8px;
        padding: 24px 40px 24px 32px;
    }

    &.hidden {
        transform: translateX(100%);
        transition: all 0.5s ease-in-out;
    }

    &.short {
        width: calc(100% - 208px);

        li {
            width: 208px;
        }
    }
`

const CategoryJobsListMobile = styled.ul`
    display: flex;
    column-gap: 24px;
    row-gap: 8px;
    flex-wrap: wrap;
    margin-top: 8px;
    padding: 0 16px;
`

const ListItem = styled.li`
    display: flex;
    gap: 8px;
    font-weight: 600;
    width: 200px;
    height: fit-content;

    span {
        color: ${props => props.theme.colors.accent};
    }

    .translated-text {
        color: ${props => props.theme.colors.main};
    }

    ${mediaQuery('md')} {
        width: calc(50% - 16px);
        font-size: ${props => props.theme.fonts.fontSizeSmall};
    }

    ${mediaQuery('mdHeight', 'max', 'height')} {
        font-size: ${props => props.theme.fonts.fontSizeSmall};
    }

    &.full-width {
        width: 100%;
    }

    ${mediaQuery('sm')} {
        &.small-full-width,
        &.full-width {
            width: 100%;
            font-size: ${props => props.theme.fonts.fontSizeSmall};
            order: 1;
        }
    }
`
const AllCat1Jobs = [
    <Text value="Category1Job1" key="Category1Job1" />,
    <span key="Separator1">|</span>,
    <Text value="Category1Job2" key="Category1Job2" />,
    <span key="Separator2">|</span>,
    <Text value="Category1Job3" key="Category1Job3" />,
    <span key="Separator3">|</span>,
    <Text value="Category1Job4" key="Category1Job4" />,
    <span key="Separator4">|</span>,
    <Text value="Category1Job5" key="Category1Job5" />,
    <span key="Separator5">|</span>,
    <Text value="Category1Job6" key="Category1Job6" />,
    <span key="Separator6">|</span>
]
const AllCat1JobsReverse = AllCat1Jobs.reverse()

const AllCat2Jobs = [
    <Text value="Category2Job1" key="Category2Job1" />,
    <span key="Separator7">|</span>,
    <Text value="Category2Job2" key="Category2Job2" />,
    <span key="Separator8">|</span>,
    <Text value="Category2Job3" key="Category2Job3" />,
    <span key="Separator9">|</span>,
    <Text value="Category2Job4" key="Category2Job4" />,
    <span key="Separator10">|</span>,
    <Text value="Category2Job5" key="Category2Job5" />,
    <span key="Separator11">|</span>
]
const AllCat2JobsReverse = AllCat2Jobs.reverse()

const AllCat3Jobs = [
    <Text value="Category3Job1" key="Category3Job1" />,
    <span key="Separator12">|</span>,
    <Text value="Category3Job2" key="Category3Job2" />,
    <span key="Separator13">|</span>,
    <Text value="Category3Job3" key="Category3Job3" />,
    <span key="Separator14">|</span>,
    <Text value="Category3Job4" key="Category3Job4" />,
    <span key="Separator15">|</span>,
    <Text value="Category3Job5" key="Category3Job5" />,
    <span key="Separator16">|</span>
]
const AllCat3JobsReverse = AllCat3Jobs.reverse()

const AllCat4Jobs = [
    <Text value="Category4Job1" key="Category4Job1" />,
    <span key="Separator17">|</span>,
    <Text value="Category4Job2" key="Category4Job2" />,
    <span key="Separator18">|</span>,
    <Text value="Category4Job3" key="Category4Job3" />,
    <span key="Separator19">|</span>,
    <Text value="Category4Job4" key="Category4Job4" />,
    <span key="Separator20">|</span>
]
const AllCat4JobsReverse = AllCat4Jobs.reverse()

const AllCat5Jobs = [
    <Text value="Category5Job1" key="Category5Job1" />,
    <span key="Separator21">|</span>,
    <Text value="Category5Job2" key="Category5Job2" />,
    <span key="Separator22">|</span>,
    <Text value="Category5Job3" key="Category5Job3" />,
    <span key="Separator23">|</span>,
    <Text value="Category5Job4" key="Category5Job4" />,
    <span key="Separator24">|</span>,
    <Text value="Category5Job5" key="Category5Job5" />,
    <span key="Separator25">|</span>,
    <Text value="Category5Job6" key="Category5Job6" />,
    <span key="Separator26">|</span>,
    <Text value="Category5Job7" key="Category5Job7" />,
    <span key="Separator27">|</span>,
    <Text value="Category5Job8" key="Category5Job8" />,
    <span key="Separator28">|</span>,
    <Text value="Category5Job9" key="Category5Job9" />,
    <span key="Separator29">|</span>
]
const AllCat5JobsReverse = AllCat5Jobs.reverse()

const AllCatJobs = [AllCat1Jobs, AllCat2Jobs, AllCat3Jobs, AllCat4Jobs, AllCat5Jobs]
const AllCatJobsReverse = [AllCat1JobsReverse, AllCat2JobsReverse, AllCat3JobsReverse, AllCat4JobsReverse, AllCat5JobsReverse]

const SliderStyling = styled.div`
    font-size: ${props => props.theme.fonts.fontSizeBackgroundText};
    line-height: 1em;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 0.1;

    ${mediaQuery('mdHeight', 'max', 'height')} {
        font-size: ${props => props.theme.fonts.fontSizeBackgroundTextSmaller};
    }
`

const BackgroundSlidersContainer = styled.div`
    position: absolute;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    left: 0;
    bottom: 200px;
    z-index: -2;
    display: flex;
    flex-direction: column;
    gap: 40px;

    ${mediaQuery('mdHeight', 'max', 'height')} {
        bottom: 64px;
    }
`

const DesktopWrapper = styled.span``
const MobileWrapper = styled.span``

const SlideWrapper = styled.div`
    display: flex;
    width: calc(100vw - 32px);
    background-color: ${props => props.theme.colors.white};
    flex-shrink: 0;
    flex-direction: column;
    padding-bottom: 24px;
    border-radius: 8px;
    overflow: hidden;
`

const mobileSlides: ReactElement[] = [
    <SlideWrapper key={0}>
        <ConsultantCategoryMobile>
            <Text value="consultantCategory1" />
        </ConsultantCategoryMobile>
        <CategoryDescription>
            <Text value="consultantCategory1Description" />
        </CategoryDescription>
        <CategoryJobsListMobile>
            <ListItem>
                <span>-</span>
                <Text value="Category1Job2" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category1Job3" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category1Job5" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category1Job6" />
            </ListItem>
            <ListItem className="full-width">
                <span>-</span>
                <Text value="Category1Job1" />
            </ListItem>
            <ListItem className="full-width">
                <span>-</span>
                <Text value="Category1Job4" />
            </ListItem>
        </CategoryJobsListMobile>
    </SlideWrapper>,
    <SlideWrapper key={1}>
        <ConsultantCategoryMobile>
            <Text value="consultantCategory2" />
        </ConsultantCategoryMobile>
        <CategoryDescription>
            <Text value="consultantCategory2Description" />
        </CategoryDescription>
        <CategoryJobsListMobile>
            <ListItem className="small-full-width">
                <span>-</span>
                <Text value="Category4Job1" />
            </ListItem>
            <ListItem className="small-full-width">
                <span>-</span>
                <Text value="Category2Job2" />
            </ListItem>
            <ListItem className="small-full-width">
                <span>-</span>
                <Text value="Category2Job3" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category2Job5" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category2Job4" />
            </ListItem>
        </CategoryJobsListMobile>
    </SlideWrapper>,
    <SlideWrapper key={2}>
        <ConsultantCategoryMobile>
            <Text value="consultantCategory3" />
        </ConsultantCategoryMobile>
        <CategoryDescription>
            <Text value="consultantCategory3Description" />
        </CategoryDescription>
        <CategoryJobsListMobile>
            <ListItem>
                <span>-</span>
                <Text value="Category3Job2" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category3Job3" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category3Job4" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category3Job5" />
            </ListItem>
            <ListItem className="full-width">
                <span>-</span>
                <Text value="Category3Job1" />
            </ListItem>
        </CategoryJobsListMobile>
    </SlideWrapper>,
    <SlideWrapper key={3}>
        <ConsultantCategoryMobile>
            <Text value="consultantCategory4" />
        </ConsultantCategoryMobile>
        <CategoryDescription>
            <Text value="consultantCategory4Description" />
        </CategoryDescription>
        <CategoryJobsListMobile>
            <ListItem className="small-full-width">
                <span>-</span>
                <Text value="Category4Job1" />
            </ListItem>
            <ListItem className="small-full-width">
                <span>-</span>
                <Text value="Category4Job4" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category4Job2" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category4Job3" />
            </ListItem>
        </CategoryJobsListMobile>
    </SlideWrapper>,
    <SlideWrapper key={4}>
        <ConsultantCategoryMobile>
            <Text value="consultantCategory5" />
        </ConsultantCategoryMobile>
        <CategoryDescription>
            <Text value="consultantCategory5Description" />
        </CategoryDescription>
        <CategoryJobsListMobile>
            <ListItem>
                <span>-</span>
                <Text value="Category5Job1" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category5Job2" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category5Job3" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category5Job4" />
            </ListItem>
            <ListItem className="small-full-width">
                <span>-</span>
                <Text value="Category5Job5" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category5Job6" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category5Job7" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category5Job8" />
            </ListItem>
            <ListItem>
                <span>-</span>
                <Text value="Category5Job9" />
            </ListItem>
        </CategoryJobsListMobile>
    </SlideWrapper>
]

const OurServices: React.FC = () => {
    const VPHeight = document.documentElement.clientHeight
    const [sectionIndex, setSectionIndex] = useState(0)
    const [sectionDescription, setSectionDescription] = useState<LangKeys>(`consultantCategory1Description`)
    const [allCurrentJobs, setAllCurrentJobs] = useState(AllCatJobs[0])
    const [allCurrentJobsReverse, setAllCurrentJobsReverse] = useState(AllCatJobsReverse[0])
    const [isVisible, setIsVisible] = useState(false)
    const containerRef = useRef(null)

    const returnVisibleState = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    }
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    }

    useEffect(() => {
        const observer = new IntersectionObserver(returnVisibleState, options)
        if (containerRef.current) observer.observe(containerRef.current)

        return () => {
            if (containerRef.current) observer.unobserve(containerRef.current)
        }
    }, [containerRef, options])

    useEffect(() => {
        const scrollHandler = () => {
            const scroll = document.documentElement.scrollTop

            if (scroll > VPHeight * 2.5 + 512) {
                setSectionIndex(4)
            } else if (scroll > VPHeight * 2.1 + 512 && scroll < VPHeight * 2.5 + 512) {
                setSectionIndex(3)
            } else if (scroll > VPHeight * 1.7 + 512 && scroll < VPHeight * 2.1 + 512) {
                setSectionIndex(2)
            } else if (scroll > VPHeight * 1.3 + 512 && scroll < VPHeight * 1.7 + 512) {
                setSectionIndex(1)
            } else {
                setSectionIndex(0)
            }
        }

        if (isVisible) {
            window.addEventListener('scroll', scrollHandler)
        }

        return () => window.removeEventListener('scroll', scrollHandler)
    })

    useEffect(() => {
        if (sectionIndex === 0) {
            setSectionDescription(`consultantCategory1Description`)
        } else if (sectionIndex === 1) {
            setSectionDescription(`consultantCategory2Description`)
        } else if (sectionIndex === 2) {
            setSectionDescription(`consultantCategory3Description`)
        } else if (sectionIndex === 3) {
            setSectionDescription(`consultantCategory4Description`)
        } else if (sectionIndex === 4) {
            setSectionDescription(`consultantCategory5Description`)
        }
        setAllCurrentJobs(AllCatJobs[sectionIndex])
        setAllCurrentJobsReverse(AllCatJobsReverse[sectionIndex])
    }, [sectionIndex])

    return (
        <SectionWrapper id="services" ref={containerRef}>
            <DesktopWrapper className="no-mobile-display">
                <HorizontalAnimationWrapper>
                    <FullScreenWrapper>
                        <SectionTitle sectionTitle="ourServicesTitle" sectionSubTitle="ourServicesSubTitle" titleAlignement="left" />
                        <ContentWrapper>
                            <SelectorAndDescriptionWrapper>
                                <ConsultantCategoryTrackingBarWrapper>
                                    <ConsultantCategory
                                        onClick={() => {
                                            window.scrollTo(0, VPHeight * 1 + 513)
                                            setSectionIndex(0)
                                        }}
                                        className={sectionIndex === 0 ? 'current' : ''}
                                        style={{ width: '184px' }}
                                    >
                                        <Text value="consultantCategory1" />
                                    </ConsultantCategory>
                                    <ConsultantCategory
                                        onClick={() => {
                                            window.scrollTo(0, VPHeight * 1.5 + 512)
                                        }}
                                        className={sectionIndex === 1 ? 'current' : ''}
                                        style={{ width: '128px' }}
                                    >
                                        <Text value="consultantCategory2" />
                                    </ConsultantCategory>
                                    <ConsultantCategory
                                        onClick={() => {
                                            window.scrollTo(0, VPHeight * 1.9 + 512)
                                        }}
                                        className={sectionIndex === 2 ? 'current' : ''}
                                        style={{ width: '72px' }}
                                    >
                                        <Text value="consultantCategory3" />
                                    </ConsultantCategory>
                                    <ConsultantCategory
                                        onClick={() => {
                                            window.scrollTo(0, VPHeight * 2.3 + 512)
                                        }}
                                        className={sectionIndex === 3 ? 'current' : ''}
                                        style={{ width: '152px' }}
                                    >
                                        <Text value="consultantCategory4" />
                                    </ConsultantCategory>
                                    <ConsultantCategory
                                        onClick={() => {
                                            window.scrollTo(0, VPHeight * 2.7 + 512)
                                        }}
                                        className={sectionIndex === 4 ? 'current' : ''}
                                        style={{ width: '120px' }}
                                    >
                                        <Text value="consultantCategory5" />
                                    </ConsultantCategory>
                                    <ConsultantCategoryCurrentTracker className={`position-${sectionIndex}`} />
                                </ConsultantCategoryTrackingBarWrapper>
                                <CategoryDescription>
                                    <Text value={sectionDescription} />
                                </CategoryDescription>
                            </SelectorAndDescriptionWrapper>
                            <PersonaesAnimationWrapper>
                                <PersonaesWrapper className={`position-${sectionIndex}`}>
                                    <PersonaeContainer>
                                        <PersonaePhoto src={Woman2} className="woman2" />
                                        <BackgroundShape>
                                            <JobsListWrapper>
                                                <CategoryJobsList className={sectionIndex === 0 ? '' : 'hidden'}>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category1Job1" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category1Job2" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category1Job3" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category1Job4" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category1Job5" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category1Job6" />
                                                    </ListItem>
                                                </CategoryJobsList>
                                            </JobsListWrapper>
                                        </BackgroundShape>
                                    </PersonaeContainer>
                                    <PersonaeContainer>
                                        <PersonaePhoto src={Man1} className="man1" />
                                        <BackgroundShape className="blue">
                                            <JobsListWrapper>
                                                <CategoryJobsList className={sectionIndex === 1 ? '' : 'hidden'}>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category2Job1" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category2Job2" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category2Job3" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category2Job4" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category2Job5" />
                                                    </ListItem>
                                                </CategoryJobsList>
                                            </JobsListWrapper>
                                        </BackgroundShape>
                                    </PersonaeContainer>
                                    <PersonaeContainer>
                                        <PersonaePhoto src={Man2} className="man2" />
                                        <BackgroundShape>
                                            <JobsListWrapper>
                                                <CategoryJobsList className={sectionIndex === 2 ? '' : 'hidden'}>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category3Job1" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category3Job2" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category3Job3" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category3Job4" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category3Job5" />
                                                    </ListItem>
                                                </CategoryJobsList>
                                            </JobsListWrapper>
                                        </BackgroundShape>
                                    </PersonaeContainer>
                                    <PersonaeContainer>
                                        <PersonaePhoto src={Woman1} className="" />
                                        <BackgroundShape className="blue">
                                            <JobsListWrapper>
                                                <CategoryJobsList className={sectionIndex === 3 ? '' : 'hidden'}>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category4Job1" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category4Job2" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category4Job3" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category4Job4" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category4Job5" />
                                                    </ListItem>
                                                </CategoryJobsList>
                                            </JobsListWrapper>
                                        </BackgroundShape>
                                    </PersonaeContainer>
                                    <PersonaeContainer>
                                        <PersonaePhoto src={Woman3} className="woman3" />
                                        <BackgroundShape>
                                            <JobsListWrapper>
                                                <CategoryJobsList className={sectionIndex === 4 ? '' : 'hidden'}>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category5Job1" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category5Job2" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category5Job3" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category5Job4" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category5Job5" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category5Job6" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category5Job7" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category5Job8" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <span>-</span>
                                                        <Text value="Category5Job9" />
                                                    </ListItem>
                                                </CategoryJobsList>
                                            </JobsListWrapper>
                                        </BackgroundShape>
                                    </PersonaeContainer>
                                </PersonaesWrapper>
                            </PersonaesAnimationWrapper>
                            <BackgroundSlidersContainer>
                                <SliderStyling>
                                    <InfiniteSlider
                                        itemsList={allCurrentJobsReverse}
                                        itemSize={['auto', 'auto']}
                                        spacing={80}
                                        direction="right"
                                        cycleDuration="60s"
                                        textColor="accent"
                                    />
                                </SliderStyling>
                                <SliderStyling>
                                    <InfiniteSlider
                                        itemsList={allCurrentJobs}
                                        itemSize={['auto', 'auto']}
                                        spacing={80}
                                        direction="left"
                                        cycleDuration="60s"
                                        textColor="main"
                                    />
                                </SliderStyling>
                            </BackgroundSlidersContainer>
                        </ContentWrapper>
                    </FullScreenWrapper>
                </HorizontalAnimationWrapper>
                <Benefits format="desktop" />
            </DesktopWrapper>
            <MobileWrapper className="no-desk-display">
                <span style={{ display: 'flex', padding: '96px 16px 40px 16px' }}>
                    <SectionTitle sectionTitle="ourServicesTitle" sectionSubTitle="ourServicesSubTitle" titleAlignement="left" />
                </span>
                <MobileSlider slidesList={mobileSlides} />
            </MobileWrapper>
        </SectionWrapper>
    )
}

export default OurServices
