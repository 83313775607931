import * as React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import HomePage from './pages/home'
import NotFoundPage from './pages/notFound'

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router
