import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import ts from 'typescript'
import { useTranslationContext } from '../../hooks/useTranslation'
import mediaQuery from '../../styles/media'

const Wrapper = styled.span`
    display: flex;
    justify-content: center;
`

const SlotWrapper = styled.span`
    display: flex;
    position: relative;
    flex-direction: column;
`

const WordWrapper = styled.span`
    position: relative;
    display: flex;
    padding: 0 8px;
    height: 1.2em;

    ::after {
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        background: ${props => props.theme.colors.accent};
        animation-name: flickering;
        animation: flickering 1s steps(2, jump-none) infinite;

        @keyframes flickering {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }

    &.selected {
        color: ${props => props.theme.colors.white};
        background: ${props => props.theme.colors.accent};
    }

    ${mediaQuery('md')} {
        padding: 0;
        ::after {
            width: 2px;
        }
    }
`

const TypingAnimation: React.FC = () => {
    const { t, language } = useTranslationContext()

    const Complements = useMemo(
        () => [
            t('mainTitleComplement1'),
            t('mainTitleComplement2'),
            t('mainTitleComplement3'),
            t('mainTitleComplement4'),
            t('mainTitleComplement5')
        ],
        [language]
    )

    const [DecomposedComplements, setDecomposedComplements] = useState<Array<Array<string>>>([])
    const [CurrentComplement, setCurrentComplement] = useState<string[]>([])
    const [DisplayedComplement, setDisplayedComplement] = useState<string>('')
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        const data: Array<Array<string>> = []

        Complements.forEach(word => {
            data.push([])
            for (let i = 0; i < word.length; i += 1) {
                data[Complements.indexOf(word)].push(word.slice(i, i + 1))
            }
        })

        setCurrentComplement(data[0])
        setDecomposedComplements(data)
    }, [language])

    useEffect(() => {
        let index = 0
        let timeoutSelected: NodeJS.Timeout
        let timeoutClean: NodeJS.Timeout

        const timeout = setInterval(() => {
            if (index < CurrentComplement.length) {
                setDisplayedComplement(prevState => prevState + CurrentComplement[index])
                index += 1
            } else {
                timeoutSelected = setTimeout(() => {
                    setIsSelected(true)
                }, 500)

                timeoutClean = setTimeout(() => {
                    if (DecomposedComplements.indexOf(CurrentComplement) < DecomposedComplements.length - 1) {
                        setCurrentComplement(DecomposedComplements[DecomposedComplements.indexOf(CurrentComplement) + 1])
                    } else {
                        setCurrentComplement(DecomposedComplements[0])
                    }
                    setIsSelected(false)
                    setDisplayedComplement('')
                }, 750)

                clearInterval(timeout)
            }
        }, 150)

        return () => {
            clearInterval(timeout)
            clearTimeout(timeoutClean)
            clearTimeout(timeoutSelected)
            setDisplayedComplement('')
            setIsSelected(false)
        }
    }, [CurrentComplement])

    return (
        <Wrapper>
            <SlotWrapper>
                <WordWrapper className={isSelected ? 'selected' : ''}>{DisplayedComplement}</WordWrapper>
            </SlotWrapper>
        </Wrapper>
    )
}

export default TypingAnimation
