import React, { useContext } from 'react'
import { defaultLanguage, LangKeys, Translation, dictionaryList, LangOption, LanguageType } from './types'

export interface TranslationContextProps {
    language: LangOption
    dictionary: LanguageType
    t: Translation
    changeLanguage: (lang: LangOption) => void
}

export const TranslationContext = React.createContext<TranslationContextProps>({
    language: 'fr',
    dictionary: dictionaryList[defaultLanguage],
    t: (tid: LangKeys, _?: string) => tid,
    changeLanguage: async (lang: LangOption) => dictionaryList[lang]
})
