import React, { ReactElement } from 'react'
import styled from 'styled-components'
import DynamicIcon from './DynamicIcon'

interface CTAProps {
    text?: string | ReactElement
    icon?: string
    iconSize?: number
    weight: string
    type: string
    color: string
    target?: string
    width?: string
    display?: string
    padding?: string
    onClick?: () => void
    className?: string
    rounded: boolean
}

const StyledLink = styled.a<{ rounded: boolean }>`
    display: flex;
    box-sizing: border-box;
    height: fit-content;
    width: fit-content;
    transition: all 0.25s ease-in-out;
    gap: 8px;
    align-items: center;
    letter-spacing: 0.075em;
    border-radius: ${props => (props.rounded ? 4 : 0)}px;

    svg {
        transition: all 0.25s ease-in-out;
    }

    &.white {
        background-color: ${props => props.theme.colors.white};
        border-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.main};

        &.text-and-icon,
        &.icon {
            &.primary {
                fill: ${props => props.theme.colors.main};
            }
        }

        fill: ${props => props.theme.colors.white};

        &.tertiary {
            background: none;
            border: none;
            fill: ${props => props.theme.colors.white};
            color: ${props => props.theme.colors.white};
        }
    }

    &.main {
        background-color: ${props => props.theme.colors.main};
        border-color: ${props => props.theme.colors.main};
        color: ${props => props.theme.colors.white};

        &.text-and-icon,
        &.icon {
            &.primary {
                fill: ${props => props.theme.colors.white};
            }
        }
        fill: ${props => props.theme.colors.main};

        &.tertiary {
            background: none;
            border: none;
            fill: ${props => props.theme.colors.main};
            color: ${props => props.theme.colors.main};
        }
    }

    &.primary,
    &.tertiary {
        &.text {
            padding: 14px 32px;
        }

        &.icon {
            padding: 12px;
        }

        &.text-and-icon {
            padding: 12px 32px;
        }
    }

    &.secondary {
        background: none;
        border-width: 2px;
        border-style: solid;

        &.main {
            color: ${props => props.theme.colors.main};
        }

        &.white {
            color: ${props => props.theme.colors.white};
        }

        &.text {
            padding: 12px 30px;
        }

        &.icon {
            padding: 10px;
        }

        &.text-and-icon {
            padding: 10px 30px;
        }
    }

    &.full-width {
        width: 100%;
        justify-content: center;
    }

    &:hover {
        background-color: ${props => props.theme.colors.mainLighter};
        border-color: ${props => props.theme.colors.mainLighter};
        color: ${props => props.theme.colors.white};
        cursor: pointer;

        span {
            fill: ${props => props.theme.colors.white} !important;
        }
    }
`

const CTA: React.FC<CTAProps> = ({
    text,
    icon,
    iconSize,
    weight,
    type,
    color,
    target,
    width,
    display,
    padding,
    onClick,
    className,
    rounded
}) => {
    let StyledCTA = <></>
    if (type === 'text') {
        StyledCTA = (
            <StyledLink
                onClick={onClick}
                className={`${weight} ${color} ${type} ${width} ${display} ${className}`}
                href={target}
                style={{ padding: `${padding}` }}
                rounded={rounded}
            >
                {text}
            </StyledLink>
        )
    } else if (type === 'icon') {
        StyledCTA = (
            <StyledLink
                onClick={onClick}
                className={`${weight} ${color} ${type} ${width} ${display} ${className}`}
                href={target}
                style={{ padding: `${padding}` }}
                rounded={rounded}
            >
                <DynamicIcon icon={icon} size={iconSize} />
            </StyledLink>
        )
    } else if (type === 'text-and-icon') {
        StyledCTA = (
            <StyledLink
                onClick={onClick}
                className={`${weight} ${color} ${type} ${width} ${display} ${className}`}
                href={target}
                style={{ padding: `${padding}` }}
                rounded={rounded}
            >
                <DynamicIcon icon={icon} size={iconSize} />
                {text}
            </StyledLink>
        )
    }

    return <>{StyledCTA}</>
}

export default CTA
