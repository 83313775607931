import fr from '../assets/locales/fr/translation.json'
import en from '../assets/locales/en/translation.json'

export type LangOption = 'fr' | 'en'

export const defaultLanguage: LangOption = /^en\b/.test(navigator.language) ? 'en' : 'fr'

export const languageOptions = {
    en: 'EN',
    fr: 'FR'
}

export interface Resources {
    fr: typeof fr
    en: typeof en
}

export type LanguageType = typeof en | typeof fr

export const dictionaryList: Resources = { en, fr }

export type LangKeys = keyof typeof dictionaryList[LangOption]

export type Translation = (tid: LangKeys, value?: string) => string
