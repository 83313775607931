import React from 'react'
import { useTranslationContext } from '../../hooks/useTranslation'
import { LangKeys } from '../../i18n/types'

interface TextProps {
    value: LangKeys
}

const Text: React.FC<TextProps> = ({ value }) => {
    const { t } = useTranslationContext()

    return (
        <span style={{ pointerEvents: 'none' }} className="translated-text">
            {t(value)}
        </span>
    )
}

export default Text
