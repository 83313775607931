import React, { useState } from 'react'
import styled from 'styled-components'
import Text from '../common/Text'
import mediaQuery from '../../styles/media'
import CTA from '../common/CTA'

import imgIntern from '../../assets/images/intern.jpg'
import imgConsultant from '../../assets/images/consultants.jpg'
import SectionTitle from '../common/SectionTitle'
import ModalNoForm from '../common/ModalNoForm'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    z-index: 1;
    min-height: 100vh;
    padding: 0 calc((100% - 1128px) / 2);
    color: #000000;
    overflow: hidden;

    ${mediaQuery('xl')} {
        margin: 80px 0;
    }

    ${mediaQuery('md')} {
        margin: 0;
        height: fit-content;
        max-height: fit-content;
        min-height: fit-content;
        padding: 80px 16px;
    }

    h2 {
        color: ${props => props.theme.colors.main};
    }
`

const MobileCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: ${props => props.theme.colors.white};
    box-shadow: 0px 8px 80px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;

    &.consultant {
        background: ${props => props.theme.colors.main};
        color: ${props => props.theme.colors.white};

        h4 {
            margin-bottom: 22px;
        }

        li {
            color: ${props => props.theme.colors.white};
        }

        p {
            color: ${props => props.theme.colors.white};
        }
    }

    :not(.consultant) {
        color: ${props => props.theme.colors.main};
    }

    img {
        height: auto;
        max-height: 120px;
        width: 100%;
        object-fit: cover;
    }

    ${mediaQuery('md', 'min')} {
        display: none;
    }
`

const MobileCardSwitch = styled.div`
    display: flex;
    width: 100%;
    margin: auto;
    padding: 16px 0;
    justify-content: center;
    align-items: center;

    div {
        font-size: 1.5rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        opacity: 50%;
        font-weight: bold;

        &.active {
            position: relative;
            opacity: 100%;

            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 48px;
                height: 2px;
                background: #c9a640;
                margin: auto;
                left: 0;
                right: 0;
                bottom: -8px;
            }
        }

        ${mediaQuery('sm')} {
            text-transform: none;
        }
    }
`

const MobileCardSwitchButton = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 32px !important;
    height: 16px;
    margin: auto 8px;
    background: rgba(0, 56, 79, 0.25);
    border-radius: 16px;
    padding: 2px;
    box-sizing: border-box;

    &.consultant {
        background: rgba(252, 252, 252, 0.5);
        justify-content: flex-end;
        color: ${props => props.theme.colors.white};

        span {
            background: ${props => props.theme.colors.white};
        }

        p {
            color: ${props => props.theme.colors.white};
        }
    }

    :not(.consultant) {
        color: ${props => props.theme.colors.main};
    }

    span {
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background: ${props => props.theme.colors.main};
    }
`

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 640px;
    box-shadow: 0px 8px 80px rgba(0, 0, 0, 0.04);
    background: ${props => props.theme.colors.white};
    border-radius: 8px;

    &:first-of-type {
        margin-right: 24px;
        color: ${props => props.theme.colors.main};
    }

    &:last-of-type {
        background: ${props => props.theme.colors.main};
        color: ${props => props.theme.colors.white};

        li {
            color: ${props => props.theme.colors.white};
        }

        p {
            color: ${props => props.theme.colors.white};
        }
    }

    img {
        height: auto;
        max-height: 240px;
        width: 100%;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
    }

    ${mediaQuery('md')} {
        display: none;
    }
`
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 32px;
    text-align: center;
    justify-content: space-between;
    height: 100%;

    div {
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    h4 {
        font-size: ${props => props.theme.fonts.fontSizeIntermediate};
        font-weight: 600;
    }

    ul {
        display: flex;
        margin: auto;
        align-items: center;

        &:first-of-type {
            margin-bottom: 8px;
        }

        hr {
            background-color: #c9a640;
            height: 16px;
            width: 2px;
            margin: 0 16px;
            border: 0;
            outline: none;
        }

        li {
            font-size: 2rem;
            font-weight: bold;
            line-height: 3rem;
            color: #002d40;
        }
    }

    ${mediaQuery('md')} {
        padding: 16px;

        ul {
            li {
                font-size: ${props => props.theme.fonts.fontSizeSmall};
            }

            hr {
                margin: 0 8px;
            }
        }
    }
`

const RecruitWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 40px;

    ::before {
        content: '';
        position: absolute;
        height: 120px;
        width: 100vw;
        top: 80px;
        background-color: ${props => props.theme.colors.accent20percent};
        display: flex;
    }

    ::after {
        content: '';
        position: absolute;
        height: 240px;
        width: 240px;
        bottom: -40px;
        left: -120px;
        border: 32px solid ${props => props.theme.colors.main20percent};
        display: flex;
        z-index: -1;
        transform: rotate(-165deg);
    }

    ${mediaQuery('md')} {
        flex-direction: column;
        margin: 0 0;

        ::before {
            display: none;
        }
    }
`

const JoinUs: React.FC = () => {
    const [show, setShow] = useState(false)
    const [isActive, setActive] = useState(false)
    const [modalType, setModalType] = useState('intern')

    const toggleClass = () => {
        setActive(!isActive)
    }

    return (
        <>
            <Wrapper id="hiring">
                <SectionTitle
                    className="no-mobile-display"
                    sectionTitle="job.offers.title"
                    sectionSubTitle="job.offers.subtitle"
                    titleAlignement="right"
                    decorated
                    decorationStyle={2}
                />
                <SectionTitle
                    className="no-desk-display"
                    sectionTitle="job.offers.title"
                    sectionSubTitle="job.offers.subtitle"
                    titleAlignement="left"
                />
                <RecruitWrapper>
                    <CardWrapper>
                        <img src={imgIntern} alt="" />
                        <TextWrapper>
                            <h4>
                                <Text value="job.offers.intern.title" />
                            </h4>
                            <p>
                                <Text value="job.offers.intern.intro" />
                            </p>
                            <div>
                                <ul>
                                    <li>
                                        <Text value="job.offers.intern.job1" />
                                    </li>
                                    <hr />
                                    <li>
                                        <Text value="job.offers.intern.job2" />
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Text value="job.offers.intern.loc" />
                                    </li>
                                </ul>
                            </div>
                            <p>
                                <Text value="job.offers.intern.text" />
                            </p>
                            <CTA
                                text={<Text value="job.offers.intern.cta" />}
                                type="text"
                                color="main"
                                weight="primary"
                                width="full-width"
                                onClick={() => {
                                    setModalType('intern')
                                    setShow(true)
                                    document.body.classList.add('prevent_scrolling')
                                }}
                                rounded
                            />
                        </TextWrapper>
                    </CardWrapper>
                    <MobileCardWrapper className={isActive ? 'consultant' : undefined}>
                        <img src={isActive ? imgConsultant : imgIntern} alt="" />
                        <MobileCardSwitch onClick={toggleClass}>
                            <div className={isActive ? undefined : 'active'}>
                                <Text value="job.offers.mobile.intern" />
                            </div>
                            <MobileCardSwitchButton className={isActive ? 'consultant' : undefined}>
                                <span />
                            </MobileCardSwitchButton>
                            <div className={isActive ? 'active' : undefined}>
                                <Text value="job.offers.mobile.consultant" />
                            </div>
                        </MobileCardSwitch>
                        <TextWrapper>
                            <h4>{isActive ? <Text value="job.offers.consultant.title" /> : <Text value="job.offers.intern.title" />}</h4>
                            <p className={isActive ? 'no-display' : ''}>{isActive ? '' : <Text value="job.offers.intern.intro" />}</p>
                            {isActive ? (
                                <>
                                    <p>
                                        <Text value="job.offers.consultant.text" />
                                    </p>
                                    <div>
                                        <ul>
                                            <li>
                                                <Text value="job.offers.consultant.job1" />
                                            </li>
                                            <hr />
                                            <li>
                                                <Text value="job.offers.consultant.job2" />
                                            </li>
                                            <hr />
                                            <li>
                                                <Text value="job.offers.consultant.job4" />
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <Text value="job.offers.consultant.job5" />
                                            </li>
                                            <hr />
                                            <li>
                                                <Text value="job.offers.consultant.job3" />
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        <Text value="job.offers.consultant.text2" />
                                    </p>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <ul>
                                            <li>
                                                <Text value="job.offers.intern.job1" />
                                            </li>
                                            <hr />
                                            <li>
                                                <Text value="job.offers.intern.job2" />
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <Text value="job.offers.intern.loc" />
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        <Text value="job.offers.intern.text" />
                                    </p>
                                </>
                            )}
                            {isActive ? (
                                <CTA
                                    text={<Text value="job.offers.consultant.cta" />}
                                    type="text"
                                    color="white"
                                    weight="primary"
                                    width="full-width"
                                    onClick={() => {
                                        setModalType('consultant')
                                        setShow(true)
                                        document.body.classList.add('prevent_scrolling')
                                    }}
                                    padding="14px"
                                    rounded
                                />
                            ) : (
                                <CTA
                                    text={<Text value="job.offers.intern.cta" />}
                                    type="text"
                                    color="main"
                                    weight="primary"
                                    width="full-width"
                                    onClick={() => {
                                        setModalType('intern')
                                        setShow(true)
                                        document.body.classList.add('prevent_scrolling')
                                    }}
                                    padding="14px"
                                    rounded
                                />
                            )}
                        </TextWrapper>
                    </MobileCardWrapper>
                    <CardWrapper>
                        <img src={imgConsultant} alt="" />
                        <TextWrapper>
                            <h4>
                                <Text value="job.offers.consultant.title" />
                            </h4>
                            <p>
                                <Text value="job.offers.consultant.text" />
                            </p>
                            <div>
                                <ul>
                                    <li>
                                        <Text value="job.offers.consultant.job1" />
                                    </li>
                                    <hr />
                                    <li>
                                        <Text value="job.offers.consultant.job2" />
                                    </li>
                                    <hr />
                                    <li>
                                        <Text value="job.offers.consultant.job3" />
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Text value="job.offers.consultant.job4" />
                                    </li>
                                    <hr />
                                    <li>
                                        <Text value="job.offers.consultant.job5" />
                                    </li>
                                </ul>
                            </div>
                            <p>
                                <Text value="job.offers.consultant.text2" />
                            </p>
                            <CTA
                                text={<Text value="job.offers.consultant.cta" />}
                                type="text"
                                color="white"
                                weight="primary"
                                width="full-width"
                                onClick={() => {
                                    setModalType('consultant')
                                    setShow(true)
                                    document.body.classList.add('prevent_scrolling')
                                }}
                                rounded
                            />
                        </TextWrapper>
                    </CardWrapper>
                </RecruitWrapper>
            </Wrapper>
            <ModalNoForm show={show} setShow={setShow} modalType={modalType} />
        </>
    )
}

export default JoinUs
