import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import CTA from '../common/CTA'
import SectionTitle from '../common/SectionTitle'
import Text from '../common/Text'
import mediaQuery from '../../styles/media'

import Novidys from '../../assets/images/clients/white/Novidys.png'
import Odigo from '../../assets/images/clients/white/Odigo.png'
import Safran from '../../assets/images/clients/white/Safran.png'
import Saur from '../../assets/images/clients/white/Saur.svg'
import Toyota from '../../assets/images/clients/white/Toyota.svg'
import ToyotaColors from '../../assets/images/clients/Toyota.svg'
import SafranColors from '../../assets/images/clients/Safran.png'
import SaurColors from '../../assets/images/clients/Saur.svg'
import NovidysColors from '../../assets/images/clients/CS_Group.png'
import OdigoColors from '../../assets/images/clients/Odigo.svg'
import Nathalie from '../../assets/images/partners/Nathalie.jpg'
import Jerome from '../../assets/images/partners/Jerome.jpg'
import Samuel from '../../assets/images/partners/Samuel.jpg'
import Ludovic from '../../assets/images/partners/Ludovic.jpg'
import Valerie from '../../assets/images/partners/Valerie.jpg'
import Alexandre from '../../assets/images/partners/Alexandre.jpg'
import Sebastien from '../../assets/images/partners/Sebastien.jpg'
import Thomas from '../../assets/images/partners/Thomas.jpg'
import Justine from '../../assets/images/partners/Justine.jpg'
import JeanMarc from '../../assets/images/partners/Jean-Marc.jpg'
import AMAPIIColors from '../../assets/icons/amapii.svg'
import AMAPII from '../../assets/icons/amapii-white.svg'

interface Profil {
    profilType: ReactElement
    name: ReactElement
    job: ReactElement
    review: ReactElement
    photo: string
    company: string
    companyMobile: string
}

const AllProfils: Profil[] = [
    {
        profilType: <Text value="reviewer.profil.type.company-partner" />,
        name: <Text value="reviewer.profil.name.sebastien" />,
        job: <Text value="reviewer.profil.job.sebastien" />,
        review: <Text value="reviewer.profil.review.sebastien" />,
        photo: Sebastien,
        company: OdigoColors,
        companyMobile: Odigo
    },
    {
        profilType: <Text value="reviewer.profil.type.consultant" />,
        name: <Text value="reviewer.profil.name.nathalie" />,
        job: <Text value="reviewer.profil.job.nathalie" />,
        review: <Text value="reviewer.profil.review.nathalie" />,
        photo: Nathalie,
        company: SafranColors,
        companyMobile: Safran
    },
    {
        profilType: <Text value="reviewer.profil.type.AMAPII" />,
        name: <Text value="reviewer.profil.name.jean-marc" />,
        job: <Text value="reviewer.profil.job.jean-marc" />,
        review: <Text value="reviewer.profil.review.jean-marc" />,
        photo: JeanMarc,
        company: AMAPIIColors,
        companyMobile: AMAPII
    },
    {
        profilType: <Text value="reviewer.profil.type.consultant" />,
        name: <Text value="reviewer.profil.name.ludovic" />,
        job: <Text value="reviewer.profil.job.ludovic" />,
        review: <Text value="reviewer.profil.review.ludovic" />,
        photo: Ludovic,
        company: SafranColors,
        companyMobile: Safran
    },
    {
        profilType: <Text value="reviewer.profil.type.consultant" />,
        name: <Text value="reviewer.profil.name.jerome" />,
        job: <Text value="reviewer.profil.job.jerome" />,
        review: <Text value="reviewer.profil.review.jerome" />,
        photo: Jerome,
        company: SaurColors,
        companyMobile: Saur
    },
    {
        profilType: <Text value="reviewer.profil.type.consultant.f" />,
        name: <Text value="reviewer.profil.name.valerie" />,
        job: <Text value="reviewer.profil.job.valerie" />,
        review: <Text value="reviewer.profil.review.valerie" />,
        photo: Valerie,
        company: SafranColors,
        companyMobile: Safran
    },
    {
        profilType: <Text value="reviewer.profil.type.consultant" />,
        name: <Text value="reviewer.profil.name.alexandre" />,
        job: <Text value="reviewer.profil.job.alexandre" />,
        review: <Text value="reviewer.profil.review.alexandre" />,
        photo: Alexandre,
        company: NovidysColors,
        companyMobile: Novidys
    },
    {
        profilType: <Text value="reviewer.profil.type.consultant" />,
        name: <Text value="reviewer.profil.name.thomas" />,
        job: <Text value="reviewer.profil.job.thomas" />,
        review: <Text value="reviewer.profil.review.thomas" />,
        photo: Thomas,
        company: ToyotaColors,
        companyMobile: Toyota
    },
    {
        profilType: <Text value="reviewer.profil.type.AMAPII.f" />,
        name: <Text value="reviewer.profil.name.justine" />,
        job: <Text value="reviewer.profil.job.justine" />,
        review: <Text value="reviewer.profil.review.justine" />,
        photo: Justine,
        company: AMAPIIColors,
        companyMobile: AMAPII
    },
    {
        profilType: <Text value="reviewer.profil.type.consultant" />,
        name: <Text value="reviewer.profil.name.samuel" />,
        job: <Text value="reviewer.profil.job.samuel" />,
        review: <Text value="reviewer.profil.review.samuel" />,
        photo: Samuel,
        company: SaurColors,
        companyMobile: Saur
    }
]

const SectionWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 64px;
    min-height: 100vh;
    overflow-x: hidden;
    padding-top: 48px;

    h2 {
        color: ${props => props.theme.colors.main};
    }

    ::before {
        content: '';
        position: absolute;
        height: 400px;
        width: 400px;
        top: 120px;
        right: -120px;
        border: 56px solid ${props => props.theme.colors.main20percent};
        display: flex;
        z-index: -1;
        transform: rotate(-165deg);
    }

    ${mediaQuery('md')} {
        gap: 40px;
        margin: 80px 0;

        ::before {
            display: none;
        }
    }
`

const ReviewsWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 calc((100% - 1128px) / 2);

    ${mediaQuery('md')} {
        padding: 80px 16px;
        flex-direction: column;
        width: 100vw;
        gap: 24px;
    }
`

const ReviewContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 672px;
    height: 416px;
    margin-right: 288px;
    padding: 56px 144px 40px 72px;
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadow.classic};

    :before {
        content: '';
        display: block;
        position: absolute;
        width: 360px;
        height: 360px;
        background-color: ${props => props.theme.colors.accent};
        opacity: 0.2;
        transform: rotate(75deg);
        z-index: -1;
        right: -144px;
        bottom: 0;
    }

    ${mediaQuery('md')} {
        width: 100%;
        height: 576px;
        padding: 0;
        margin: 0;

        :before {
            left: -144px;
            bottom: -32px;
            transform: rotate(105deg);
        }
    }
`

const ReviewContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h5 {
        transition: all 0.25s ease-in-out;

        &.hidden {
            opacity: 0;
        }
    }

    p {
        margin: 24px 0 auto 0;
        transition: all 0.25s ease-in-out;

        &.hidden {
            opacity: 0;
        }
    }

    .company-logo {
        height: 48px;
        align-self: flex-start;
        transition: all 0.25s ease-in-out;

        &.hidden {
            opacity: 0;
        }
    }

    ${mediaQuery('md')} {
        justify-content: flex-start;
        box-sizing: border-box;
        overflow: hidden;
        padding: 24px 16px;

        p {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            margin: 16px 0 0 0;
            overflow: scroll;
        }
    }
`

const ReviewerInfo = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${props => props.theme.colors.main};
    transition: all 0.25s ease-in-out;

    .square-decoration {
        display: block;
        width: 4px;
        height: 4px;
        background-color: ${props => props.theme.colors.accent};
    }

    h4 {
        margin: 0;
    }

    &.hidden {
        opacity: 0;
    }

    ${mediaQuery('sm')} {
        flex-direction: column;
        align-items: flex-start;

        .square-decoration {
            display: none;
        }
    }
`

const ReviewerPhoto = styled.img`
    width: 360px;
    height: 328px;
    object-fit: cover;
    position: absolute;
    bottom: 16px;
    right: -288px;
    border-radius: 8px;

    ${mediaQuery('md')} {
        position: relative;
        order: -1;
        width: 100%;
        height: 240px;
        right: 0;
        top: 0;
        object-position: top;
    }
`

const ReviewsControls = styled.div`
    display: flex;
    position: absolute;
    bottom: -16px;
    right: 0px;
    z-index: 5;
    border-radius: 4px;
    overflow: hidden;
`

const ReviewsMobileControls = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.main};
    padding-left: 16px;

    .company-logo {
        height: 24px;
        align-self: center;
    }
`

const DecorationSquares = styled.div`
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    top: -48px;
    left: -72px;

    span {
        display: block;
        position: absolute;

        &:nth-of-type(1) {
            width: 10px;
            height: 10px;
            background-color: ${props => props.theme.colors.accent};
            top: 27px;
            left: 54px;
            border-radius: 2px;
        }

        &:nth-of-type(2) {
            width: 16px;
            height: 16px;
            background-color: ${props => props.theme.colors.main};
            top: 45px;
            left: 43px;
            border-radius: 2px;
        }

        &:nth-of-type(3) {
            width: 32px;
            height: 32px;
            border: 4px solid ${props => props.theme.colors.main};
            box-sizing: border-box;
            top: 0;
            left: 14px;
            border-radius: 6px;
        }
    }

    ${mediaQuery('md')} {
        top: -72px;
        left: auto;
        right: -14px;

        span {
            &:nth-of-type(1) {
                top: 30px;
                left: 6px;
                background-color: ${props => props.theme.colors.main};
            }

            &:nth-of-type(2) {
                top: 5px;
                left: 12px;
                background-color: ${props => props.theme.colors.accent};
            }

            &:nth-of-type(3) {
                top: 27px;
                left: 26px;
            }
        }
    }

    ${mediaQuery('sm')} {
        top: -102px;
        right: -6px;
        transform: scale(-1);
    }
`

const ReviewsAndPartners: React.FC = () => {
    const LogoAlt = `Logo ${SafranColors.slice()}`
    const [reviewIndex, setReviewIndex] = useState(0)
    const [contentFadeClass, setContentFadeClass] = useState('')

    const nextReview = (): void => {
        if (reviewIndex < AllProfils.length - 1) {
            setReviewIndex(reviewIndex + 1)
        } else {
            setReviewIndex(0)
        }
    }

    const previousReview = (): void => {
        if (reviewIndex > 0) {
            setReviewIndex(reviewIndex - 1)
        } else {
            setReviewIndex(AllProfils.length - 1)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setContentFadeClass('')
        }, 250)
    }, [contentFadeClass])

    return (
        <SectionWrapper id="partners">
            <SectionTitle
                className="no-mobile-display"
                sectionTitle="reviews.title"
                sectionSubTitle="reviews.subtitle"
                titleAlignement="center"
            />
            <ReviewsWrapper>
                <SectionTitle
                    className="no-desk-display"
                    sectionTitle="reviews.title"
                    sectionSubTitle="reviews.subtitle"
                    titleAlignement="left"
                />
                <ReviewContentWrapper>
                    <DecorationSquares>
                        <span />
                        <span />
                        <span />
                    </DecorationSquares>
                    <ReviewsMobileControls className="no-desk-display">
                        <img className="company-logo" src={AllProfils[reviewIndex].companyMobile} alt={LogoAlt} />
                        <span style={{ display: 'flex' }}>
                            <CTA
                                onClick={() => {
                                    setContentFadeClass('hidden')
                                    setTimeout(() => {
                                        previousReview()
                                    }, 200)
                                }}
                                type="icon"
                                weight="primary"
                                color="white"
                                icon="chevronLeft"
                                iconSize={32}
                                padding="4px"
                                rounded={false}
                            />
                            <CTA
                                onClick={() => {
                                    setContentFadeClass('hidden')
                                    setTimeout(() => {
                                        nextReview()
                                    }, 200)
                                }}
                                type="icon"
                                weight="primary"
                                color="main"
                                icon="chevronRight"
                                iconSize={32}
                                padding="4px"
                                rounded={false}
                            />
                        </span>
                    </ReviewsMobileControls>
                    <ReviewContent>
                        <h5 className={contentFadeClass}>{AllProfils[reviewIndex].profilType}</h5>
                        <ReviewerInfo className={contentFadeClass}>
                            <h4 style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{AllProfils[reviewIndex].name}</h4>
                            <span className="square-decoration" />
                            <h4>{AllProfils[reviewIndex].job}</h4>
                        </ReviewerInfo>
                        <p className={contentFadeClass}>{AllProfils[reviewIndex].review}</p>
                        <img
                            className={`company-logo no-mobile-display ${contentFadeClass}`}
                            src={AllProfils[reviewIndex].company}
                            alt={LogoAlt}
                        />
                    </ReviewContent>
                    <ReviewsControls className="no-mobile-display">
                        <CTA
                            onClick={() => {
                                setContentFadeClass('hidden')
                                setTimeout(() => {
                                    previousReview()
                                }, 200)
                            }}
                            type="icon"
                            weight="primary"
                            color="main"
                            icon="chevronLeft"
                            iconSize={40}
                            padding="8px"
                            rounded={false}
                        />
                        <CTA
                            onClick={() => {
                                setContentFadeClass('hidden')
                                setTimeout(() => {
                                    nextReview()
                                }, 200)
                            }}
                            type="icon"
                            weight="primary"
                            color="white"
                            icon="chevronRight"
                            iconSize={40}
                            padding="8px"
                            rounded={false}
                        />
                    </ReviewsControls>
                    <ReviewerPhoto src={AllProfils[reviewIndex].photo} />
                </ReviewContentWrapper>
            </ReviewsWrapper>
        </SectionWrapper>
    )
}

export default ReviewsAndPartners
