import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'

interface SliderProps {
    itemsList: ReactElement[]
    itemSize: number[] | string[]
    spacing: number
    direction?: string
    cycleDuration?: string
    textColor: string
}

const SliderContainer = styled.div`
    display: flex;
    overflow: hidden;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
`

const SlidingDiv = styled.div`
    display: flex;
    width: auto;
    flex-shrink: 0;

    &.left {
        animation-name: scrollLeft;
        animation: scrollLeft linear infinite;
        animation-duration: 30s;

        @keyframes scrollLeft {
            0% {
                transform: translateX(0%);
            }
            100% {
                transform: translateX(-100%);
            }
        }
    }
    &.right {
        animation-name: scrollRight;
        animation: scrollRight linear infinite;
        animation-duration: 30s;

        @keyframes scrollRight {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0%);
            }
        }
    }
`

const SliderItem = styled.span`
    display: flex;
    width: auto;
    height: auto;
    flex-shrink: 0;

    img {
        object-fit: contain;
        width: auto;
        max-width: 200px;
        height: 100%;
    }
`

const InfiniteSlider: React.FC<SliderProps> = ({ itemsList, itemSize, spacing, direction, cycleDuration, textColor }) => {
    let textColorComputed = ''
    /*     Fonctionne mais fait une erreur bloquante
        if (Object.prototype.hasOwnProperty.call(colors, textColor) === true) {
            textColorComputed = colors[`${textColor}`]
        } else {
            textColorComputed = textColor
        } */
    if (textColor === 'main') {
        textColorComputed = colors.main
    } else if (textColor === 'accent') {
        textColorComputed = colors.accent
    } else if (textColor === 'white') {
        textColorComputed = colors.white
    } else if (textColor === 'black') {
        textColorComputed = colors.black
    } else {
        textColorComputed = textColor
    }

    const itemsListed = itemsList.map(item => (
        <SliderItem key={item.key} style={{ width: itemSize[0], height: itemSize[1] }}>
            {item}
        </SliderItem>
    ))

    return (
        <SliderContainer>
            <div style={{ display: 'flex' }}>
                <SlidingDiv
                    className={direction === 'left' ? 'left' : 'right'}
                    style={{ gap: spacing, paddingLeft: spacing, color: textColorComputed, animationDuration: cycleDuration }}
                >
                    {itemsListed}
                </SlidingDiv>
                <SlidingDiv
                    className={direction === 'left' ? 'left' : 'right'}
                    style={{ gap: spacing, paddingLeft: spacing, color: textColorComputed, animationDuration: cycleDuration }}
                >
                    {itemsListed}
                </SlidingDiv>
            </div>
        </SliderContainer>
    )
}

export default InfiniteSlider
