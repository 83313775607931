import React from 'react'
import styled from 'styled-components'
import Media from '../../assets/media/videoCompressed.mp4'
import TypingAnimation from '../common/TypingAnimation'
import Text from '../common/Text'

const VideoWrapper = styled.div`
    height: 100vh;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: -1;
    background-color: ${props => props.theme.colors.blackRGBA};
    filter: saturate(90%) brightness(90%);

    video {
        min-width: 100%;
        height: 100vh;
        object-fit: contain;
        mask-image: radial-gradient(circle, black 33%, transparent 80%);
    }
`
const SectionWrapper = styled.div`
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
`

const FadeInRectangle = styled.div`
    position: absolute;
    height: 25%;
    width: 100%;

    &.to-bottom {
        top: 0;
        background: linear-gradient(
            to bottom,
            ${props => props.theme.colors.blackRGBA} 0%,
            ${props => props.theme.colors.blackRGBAtransparent} 100%
        );
    }

    &.to-top {
        bottom: 0;
        background: linear-gradient(
            to top,
            ${props => props.theme.colors.blackRGBA} 0%,
            ${props => props.theme.colors.blackRGBAtransparent} 100%
        );
    }
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.white};
    margin: auto;
    text-align: center;

    h1 {
        :nth-of-type(2) {
            color: ${props => props.theme.colors.accent};
        }
    }
`

const HeroSection: React.FC = () => {
    return (
        <SectionWrapper>
            <FadeInRectangle className="to-bottom" />
            <TitleWrapper>
                <h1>
                    <Text value="mainTitle" />
                </h1>
                <h1>
                    <TypingAnimation />
                </h1>
            </TitleWrapper>
            <VideoWrapper>
                <video
                    src={Media}
                    autoPlay
                    loop
                    preload="metadata"
                    muted
                    style={{ position: 'absolute', margin: 'auto', filter: 'brightness(75%)' }}
                />
            </VideoWrapper>
            <FadeInRectangle className="to-top" />
        </SectionWrapper>
    )
}

export default HeroSection
